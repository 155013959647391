import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

export type FileUploadErrorItem = {
  fileName: string
  message: string
}

export type FileUploadErrorDialogParam = {
  items: FileUploadErrorItem[]
}

@Component({
  selector: 'app-file-upload-error',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './file-upload-error.component.html',
  styleUrls: ['./file-upload-error.component.scss'],
})
export class FileUploadErrorComponent {
  param: FileUploadErrorDialogParam | null = null

  constructor(
    @Inject(MAT_DIALOG_DATA) param: FileUploadErrorDialogParam,
    private dialogRef: MatDialogRef<FileUploadErrorComponent>,
  ) {
    this.param = param
  }

  close(): void {
    this.dialogRef.close()
  }
}
