import { CommonModule, DatePipe, DecimalPipe } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { InfiniteScrollDirective } from 'ngx-infinite-scroll'
import { catchError, filter, finalize, of, switchMap, tap } from 'rxjs'

import { ButtonComponent } from '../../components/button/button.component'
import { ContextMenuComponent } from '../../components/context-menu/context-menu.component'
import { LoadingComponent } from '../../components/loading/loading.component'
import {
  DialogResult,
  MessageDialogService,
} from '../../components/message-dialog/message-dialog.service'
import { SearchInputComponent } from '../../components/search-input/search-input.component'
import {
  Assessment,
  AssessmentSummary,
} from '../../services/assessment/assessment.mapping'
import { CsvExportService } from '../../services/csv-export/csv-export.service'
import { AssessmentStore } from '../../stores/assessment.store'
import { AuthService } from '../../services/auth.service'
import { UserStore } from '../../stores/user.store'
import { getTitle } from '../../util/accessibility'

type AssessmentRow = AssessmentSummary & {
  score: number | null
  selected: boolean
}

const MAXIMUM_SELECTION_COUNT = 50

@Component({
  selector: 'app-assessment-list-page',
  standalone: true,
  imports: [
    ButtonComponent,
    MatIcon,
    MatTableModule,
    MatCheckbox,
    DatePipe,
    DecimalPipe,
    SearchInputComponent,
    CommonModule,
    LoadingComponent,
    InfiniteScrollDirective,
    ContextMenuComponent,
  ],
  templateUrl: './assessment-list-page.component.html',
  styleUrl: './assessment-list-page.component.scss',
})
export class AssessmentListPageComponent implements OnInit {
  @ViewChild('tableContainer') tableContainer: ElementRef | undefined

  readonly PAGE_SIZE_LIMIT = 100
  offset = 0

  displayedColumns: string[] = [
    'select',
    'userName',
    'userDepartmentName',
    'businessMeetingName',
    'assessDate',
    'accountName',
    'averageScore',
    'action',
  ]
  dataSource: AssessmentRow[] = []

  sortField = 'assessDate'
  sortDirection: 'asc' | 'desc' = 'desc'

  loading = true

  queryName$ = of(null)
  queryName = ''

  contextMenu = [
    {
      icon: 'delete',
      title: '削除',
      type: 'DELETE',
    },
  ]

  get checkedCount(): number {
    return this.dataSource.filter((row) => row.selected)?.length
  }
  get checkedState(): 'CHECKED' | 'UNCHECKED' | 'INDETERMINATE' {
    const checkedCount =
      this.dataSource.filter((assessment) => assessment.selected)?.length ?? 0
    if (checkedCount === 0) {
      return 'UNCHECKED'
    } else if (checkedCount === this.dataSource.length) {
      return 'CHECKED'
    } else {
      return 'INDETERMINATE'
    }
  }

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private assessmentStore: AssessmentStore,
    private csvExportService: CsvExportService,
    private messageDialogService: MessageDialogService,
    private authService: AuthService,
    private userStore: UserStore,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('アセスメント一覧'))
  }

  ngOnInit(): void {
    this.loadAssessments()
  }

  navigateToHome(userId: string, assessmentId: string) {
    this.router.navigate(['/home'], {
      queryParams: { 'user-id': userId, 'assessment-id': assessmentId },
    })
  }

  queryNameChange(queryName: string) {
    this.offset = 0
    this.queryName = queryName
    this.loadAssessments()
  }

  sort(sortField: string) {
    this.loading = true
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    } else {
      this.sortDirection = 'asc'
      this.sortField = sortField
    }
    this.offset = 0
    this.loadAssessments()
  }

  async loadMoreAssessments() {
    this.offset += 1
    this.loadAssessments()
  }

  sleep = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  exportAssessments() {
    this.assessmentStore
      .exportAssessments()
      .pipe(
        tap((res) => {
          this.csvExportService.downloadCsv(
            'assessments.csv',
            [
              { label: '名前', key: 'userName' },
              { label: 'メール', key: 'userEmail' },
              { label: '部署', key: 'departmentName' },
              { label: 'マネージャー', key: 'reportToName' },
              { label: '商談', key: 'businessMeetingTitle' },
              { label: '記録日', key: 'businessMeetingRecordDate' },
              { label: '顧客名', key: 'accountName' },
              { label: 'キーアクション', key: 'keyActionName' },
              { label: 'スコア', key: 'score' },
              { label: '評価コメント', key: 'evaluation' },
            ],
            res.data.exportAssessments,
          )
        }),
      )
      .subscribe()
  }

  selectCellClick(event: Event) {
    event.stopPropagation()
  }

  checkAllAssessments = (checked: boolean) => {
    let selectedCount = this.dataSource.filter((a) => a.selected).length
    this.dataSource.forEach((assessment) => {
      if (checked && !assessment.selected) {
        if (
          selectedCount < MAXIMUM_SELECTION_COUNT &&
          this.isDeletableAssessment(assessment)
        ) {
          assessment.selected = checked
          selectedCount += 1
        }
      } else if (!checked && assessment.selected) {
        assessment.selected = checked
      }
    })
    if (checked && selectedCount > MAXIMUM_SELECTION_COUNT) {
      this.messageDialogService.showInfo(
        `一度に選択できるのは ${MAXIMUM_SELECTION_COUNT} 件までです。`,
      )
    }
  }

  checkChanged = (checkbox: MatCheckbox, assessment: AssessmentRow) => {
    const selectedCount = this.dataSource.filter((a) => a.selected)?.length ?? 0
    if (checkbox.checked && selectedCount >= MAXIMUM_SELECTION_COUNT) {
      this.messageDialogService
        .showInfo(
          `一度に選択できるのは ${MAXIMUM_SELECTION_COUNT} 件までです。`,
        )
        .pipe(
          finalize(() => {
            checkbox.checked = false
          }),
        )
        .subscribe()
      return
    }

    assessment.selected = !assessment.selected
  }

  deleteAssessmentsBulk = () => {
    const assessmentIds = this.dataSource
      .filter((a) => a.selected)
      .map((a) => a.id)

    this.messageDialogService
      .showConfirm(
        `本当に ${assessmentIds.length} 件のアセスメントを削除してもよろしいですか？`,
        {
          primaryButtonText: '削除',
          secondaryButtonText: 'キャンセル',
        },
      )
      .pipe(
        filter((result) => result === DialogResult.PrimaryButtonClicked),
        switchMap(() => {
          return this.assessmentStore.deleteAssessments(assessmentIds).pipe(
            tap(() => {
              this.offset = 0
              this.loadAssessments()
            }),
          )
        }),
        tap(() => {
          this.snackBar.open('削除しました', 'close', {
            duration: 3000,
          })
        }),
      )
      .subscribe()
  }

  contextMenuClick(type: string, assessment: Assessment) {
    if (type === 'DELETE') {
      this.messageDialogService
        .showConfirm(
          `
          「${assessment.businessMeeting.title}」のアセスメントを削除してもよろしいですか？
          この操作は取り消すことができません。
          `,
          {
            primaryButtonText: '削除',
            secondaryButtonText: 'キャンセル',
          },
        )
        .pipe(
          filter((result) => result === DialogResult.PrimaryButtonClicked),
          switchMap(() => {
            this.loading = true
            return this.assessmentStore.deleteAssessment(assessment.id).pipe(
              catchError(() => {
                this.messageDialogService
                  .showError(
                    'アセスメントの削除に失敗しました。時間をおいて再度お試しください。',
                  )
                  .subscribe()
                return of()
              }),
            )
          }),
          tap(() => {
            this.offset = 0
            this.loadAssessments()
          }),
          finalize(() => (this.loading = false)),
        )
        .subscribe()
    }
  }

  isSelfUser(assessment: AssessmentSummary): boolean {
    return assessment.user?.id === this.authService.user?.id
  }

  isDirectManager(assessment: AssessmentSummary): boolean {
    return assessment.user?.reportTo?.id === this.authService.user?.id
  }

  isEnablerOrAdmin(): boolean {
    return (
      this.userStore
        .ctxUser()
        ?.roles.some(
          (role) => role.code === 'ENABLER' || role.code === 'SUPER_ADMIN',
        ) ?? false
    )
  }

  isDeletableAssessment(assessment: AssessmentSummary): boolean {
    return (
      this.isEnablerOrAdmin() ||
      this.isSelfUser(assessment) ||
      this.isDirectManager(assessment)
    )
  }

  private loadAssessments() {
    this.loading = true
    this.assessmentStore
      .searchAssessments(
        this.queryName ?? '',
        {
          limit: this.PAGE_SIZE_LIMIT,
          offset: this.PAGE_SIZE_LIMIT * this.offset,
        },
        {
          sortField: this.sortField,
          sortOrder: this.sortDirection,
        },
      )
      .pipe(
        tap((assessments) => {
          if (this.offset === 0) {
            this.dataSource = []
            this.scrollTop()
          }
          this.dataSource = this.dataSource.concat(
            assessments.map((assessment) => this.toAssessmentRow(assessment)),
          )
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe()
  }

  private scrollTop() {
    if (this.tableContainer?.nativeElement?.scrollTop) {
      this.tableContainer.nativeElement.scrollTop = 0
    }
  }

  private toAssessmentRow(assessment: AssessmentSummary): AssessmentRow {
    return {
      ...assessment,
      score: this.getAverageScore(assessment) || null,
      selected: false,
    }
  }

  private getAverageScore(assessment: AssessmentSummary | null): number | null {
    if (!assessment) {
      return null
    }

    if (assessment.keyActionEvaluations.length === 0) {
      return null
    }

    const totalScore = assessment.keyActionEvaluations.reduce(
      (total, keyActionEvaluation) => {
        return (
          total + (keyActionEvaluation.score ? keyActionEvaluation.score : 0)
        )
      },
      0,
    )

    return totalScore / assessment.keyActionEvaluations.length
  }
}
