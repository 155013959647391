import { Injectable, inject } from '@angular/core'
import {
  Auth,
  GoogleAuthProvider,
  User,
  authState,
  signInWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  getIdTokenResult,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '@angular/fire/auth'

import { map, tap } from 'rxjs'

export interface AuthUser {
  id: string
  name: string | null
  photoUrl: string | null
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth)

  user: AuthUser | null = null
  user$ = authState(this.auth).pipe(
    map((user) => {
      return user ? this.mappingUser(user) : null
    }),
    tap((user) => {
      this.user = user
    }),
  )

  loginWithGoogle = async () => {
    const result = await signInWithPopup(this.auth, new GoogleAuthProvider())

    const user = result.user

    const idTokenResult = await getIdTokenResult(user)

    const claims = idTokenResult.claims
    if (claims.org_id && claims.user_id) {
      return true
    } else {
      this.auth.signOut()
      return false
    }
  }

  loginWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.auth, email)
  }

  logout = () => {
    return signOut(this.auth)
  }

  verifyPasswordResetCode = (oobCode: string): Promise<string> => {
    return verifyPasswordResetCode(this.auth, oobCode)
  }

  confirmPasswordReset = (
    oobCode: string,
    newPassword: string,
  ): Promise<void> => {
    return confirmPasswordReset(this.auth, oobCode, newPassword)
  }

  private mappingUser = (user: User): AuthUser => {
    return {
      id: user.uid,
      name: user.displayName,
      photoUrl: user.photoURL,
    }
  }
}
