import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatIcon } from '@angular/material/icon'

import { zxcvbn } from '@zxcvbn-ts/core'
import { tap } from 'rxjs'

import { ButtonComponent } from '../../../components/button/button.component'
import { LoadingComponent } from '../../../components/loading/loading.component'

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    MatIcon,
    ReactiveFormsModule,
    LoadingComponent,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent implements OnInit {
  lengthSatisfied = false
  characterSatisfied = false
  complexitySatisfied = false
  strength: 'weak' | 'middle' | 'strong' | null = null
  visiblePassword = false
  visibleConfirmPassword = false
  passwordCtrl = new FormControl('')
  confirmPasswordCtrl = new FormControl('')

  @Input() isLoading = false
  @Output() passwordConfirmed = new EventEmitter<string>()

  constructor() {}

  ngOnInit(): void {
    this.passwordCtrl.valueChanges
      .pipe(
        tap((value) => {
          if (!value) return
          const result = zxcvbn(value)
          this.strength =
            result.score >= 4 ? 'strong' : result.score >= 3 ? 'middle' : 'weak'
          this.complexitySatisfied = this.strength === 'strong'
          this.lengthSatisfied = value.length >= 8
          this.characterSatisfied = this.satisfyCharacterRequirements(value)
        }),
      )
      .subscribe()
  }

  togglePasswordVisibility() {
    this.visiblePassword = !this.visiblePassword
  }

  toggleConfirmPasswordVisibility() {
    this.visibleConfirmPassword = !this.visibleConfirmPassword
  }

  isPasswordValid() {
    return (
      this.lengthSatisfied &&
      this.characterSatisfied &&
      this.complexitySatisfied &&
      this.passwordCtrl.value === this.confirmPasswordCtrl.value
    )
  }

  confirmPassword() {
    const password = this.passwordCtrl.value
    if (password && this.isPasswordValid()) {
      this.passwordConfirmed.emit(password)
    }
  }

  private satisfyCharacterRequirements(password: string) {
    const specialCharacters = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/
    return (
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /[0-9]/.test(password) &&
      specialCharacters.test(password)
    )
  }
}
