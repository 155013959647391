<input
  class="select"
  [ngClass]="{ disabled: this.disabled, error: this.showError }"
  [ngModel]="this.displayValue"
  [matAutocomplete]="autocomplete"
  appEllipsisTooltip
  [matTooltip]="this.displayValue ?? ''"
  [matTooltipShowDelay]="1000"
  (click)="onInputClick()"
  readonly
/>
<mat-autocomplete
  #autocomplete="matAutocomplete"
  [displayWith]="this.displayFn"
  (optionSelected)="this.onOptionSelected($event)"
>
  <mat-option *ngFor="let option of this.options" [value]="option">
    <div
      class="select-option"
      appEllipsisTooltip
      [matTooltip]="option.name"
      [matTooltipShowDelay]="1000"
    >
      {{ option.name }}
    </div>
  </mat-option>
</mat-autocomplete>
