import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

import { AdminOrg } from '../../../../../services/user/user.mapping'

export type LicenseExceededErrorItem = {
  userName: string
  exceededSeconds: number
}

export type LicenseExceededErrorDialogParam = {
  items: LicenseExceededErrorItem[]
  adminOrg: AdminOrg | null
}

@Component({
  selector: 'app-license-exceeded-error',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './license-exceeded-error.component.html',
  styleUrls: ['./license-exceeded-error.component.scss'],
})
export class LicenseExceededErrorComponent {
  param: LicenseExceededErrorDialogParam | null = null
  adminOrg: AdminOrg | null = null

  constructor(
    @Inject(MAT_DIALOG_DATA) param: LicenseExceededErrorDialogParam,
    private dialogRef: MatDialogRef<LicenseExceededErrorComponent>,
  ) {
    this.param = param
    this.adminOrg = param.adminOrg
  }

  getUserMonthlyLimitHours(): number {
    return this.adminOrg?.licenseInfo?.userMonthlyLimitSeconds
      ? this.secondsToHours(this.adminOrg.licenseInfo.userMonthlyLimitSeconds)
      : 0
  }

  secondsToHours(seconds: number): number {
    return Math.floor(seconds / 3600)
  }

  close(): void {
    this.dialogRef.close()
  }
}
