import { Component, Input, Output, EventEmitter } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIcon } from '@angular/material/icon'

export type UploadItem = {
  name: string
  status: 'completed' | 'error' | 'in_progress'
}

@Component({
  selector: 'app-upload-list',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.scss'],
})
export class UploadListComponent {
  @Input() expanded: boolean = false
  @Input() items: UploadItem[] = []
  @Output() closed = new EventEmitter<void>()

  get completedItems(): UploadItem[] {
    return this.items.filter((item) => item.status === 'completed')
  }

  close(): void {
    this.closed.emit()
  }
}
