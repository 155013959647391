import { CdkTrapFocus } from '@angular/cdk/a11y'
import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Output, Inject } from '@angular/core'
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox'

import { MULTIPLE_SELECT_INPUT, Item } from '../multiple-select.component'

export type InputData = {
  fieldName: string
  items: Item[]
}

@Component({
  templateUrl: './multiple-select-control.component.html',
  styleUrls: ['./multiple-select-control.component.scss'],
  standalone: true,
  imports: [MatCheckbox, CommonModule, CdkTrapFocus],
})
export class MultipleSelectControlComponent {
  items: Item[] = []
  selectAll = false
  disableSelectAll = false

  private query = ''
  get filteredItems(): Item[] {
    return this.items.filter((item) =>
      item.label.toLocaleLowerCase().includes(this.query.toLocaleLowerCase()),
    )
  }

  @Output() readonly closed = new EventEmitter<void>()
  @Output() readonly changeSelected = new EventEmitter<Item[]>()

  constructor(@Inject(MULTIPLE_SELECT_INPUT) public inputData: InputData) {
    this.items = this.inputData.items.map((item) => ({ ...item }))
    this.selectAll = this.items.every((item) => item.checked)
    this.disableSelectAll = !this.items.every((item) => !item.notSelectable)
  }

  onSelect(selected: Item, e: MatCheckboxChange): void {
    const item = this.items.find(
      (i) => (i.id || i.label) === (selected.id || selected.label),
    )
    if (!item) return
    item.checked = e.checked
    this.changeSelected.emit(this.items)
    this.changeSelectAllIfNeeded()
  }

  onSelectAll(e: MatCheckboxChange): void {
    this.selectAll = e.checked
    this.filteredItems.forEach((item) => (item.checked = e.checked))
    this.changeSelected.emit(this.items)
  }

  onSearch(query: string): void {
    this.query = query
    this.changeSelectAllIfNeeded()
  }

  private changeSelectAllIfNeeded(): void {
    if (!this.filteredItems.length) return
    if (this.filteredItems.every((i) => i.checked)) {
      this.selectAll = true
    } else if (this.filteredItems.some((i) => !i.checked)) {
      this.selectAll = false
    }
  }
}
