<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <h1 class="heading">メンバー一覧</h1>
    </div>
    <div class="search-box">
      <app-search-input
        [value]="this.queryName$ | async"
        (changed)="this.queryNameChange($event)"
        [placeholder]="'名前で検索'"
      ></app-search-input>
      <app-select
        class="select-department"
        [options]="this.selectableDepartments"
        (selectedOptionChange)="this.departmentSelected($event)"
      ></app-select>
    </div>
    <div
      #tableContainer
      class="table-container"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="loadMoreUsers()"
    >
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('name')"
          >
            名前
            <ng-container *ngIf="this.sortField === 'name'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let user">{{ user.name }}</td>
        </ng-container>

        <ng-container matColumnDef="department">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('departmentName')"
          >
            部署
            <ng-container *ngIf="this.sortField === 'departmentName'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let user">{{ user.department }}</td>
        </ng-container>

        <ng-container matColumnDef="latestBusinessMeetingName">
          <th mat-header-cell *matHeaderCellDef>最新商談</th>
          <td mat-cell *matCellDef="let user">
            {{ user.latestBusinessMeetingName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="businessMeetingCount">
          <th mat-header-cell *matHeaderCellDef>商談回数</th>
          <td mat-cell *matCellDef="let user">
            {{ user.businessMeetingCount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="latestBusinessMeetingDate">
          <th mat-header-cell *matHeaderCellDef>最新商談日</th>
          <td mat-cell *matCellDef="let user">
            {{
              user.latestBusinessMeetingDate
                ? (user.latestBusinessMeetingDate | date: 'yyyy/MM/dd')
                : ''
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="averageScore">
          <th mat-header-cell *matHeaderCellDef>総合スコア</th>
          <td mat-cell *matCellDef="let user">
            {{
              user.averageScore
                ? (user.averageScore | number: '1.1-1') + 'pts'
                : ''
            }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="navigateToUserDetail(row.id)"
        ></tr>
      </table>
      <app-loading [showLoading]="this.loading"></app-loading>
      <ng-container *ngIf="this.dataSource.length === 0 && !this.loading">
        <div class="empty">該当なし</div>
      </ng-container>
    </div>
  </div>
</div>
