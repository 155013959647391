<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <h1 class="heading">アセスメント一覧</h1>
      <button rectangle (click)="exportAssessments()">エクスポート</button>
    </div>
    <div class="search-box">
      <app-search-input
        [value]="this.queryName$ | async"
        (changed)="this.queryNameChange($event)"
        [placeholder]="'名前、部署などで検索'"
      ></app-search-input>
    </div>
    <div
      #tableContainer
      class="table-container"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="loadMoreAssessments()"
    >
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="userName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('userName')"
          >
            メンバー
            <ng-container *ngIf="this.sortField === 'userName'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let assessment">
            {{ assessment.user.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="userDepartmentName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('userDepartmentName')"
          >
            部署
            <ng-container *ngIf="this.sortField === 'userDepartmentName'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let assessment">
            {{ assessment.user.departmentName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="businessMeetingName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('businessMeetingName')"
          >
            商談
            <ng-container *ngIf="this.sortField === 'businessMeetingName'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let assessment">
            {{ assessment.businessMeeting.title }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assessDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('assessDate')"
          >
            アセスメント実施日
            <ng-container *ngIf="this.sortField === 'assessDate'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let assessment">
            {{ assessment.assessDate | date: 'yyyy/MM/dd' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="accountName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="sortable"
            (click)="this.sort('accountName')"
          >
            顧客名
            <ng-container *ngIf="this.sortField === 'accountName'">
              {{ this.sortDirection === 'asc' ? '▲' : '▼' }}
            </ng-container>
          </th>
          <td mat-cell *matCellDef="let assessment">
            {{ assessment.businessMeeting.account.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="averageScore">
          <th mat-header-cell *matHeaderCellDef>スコア</th>
          <td mat-cell *matCellDef="let assessment">
            {{ (assessment.score | number: '1.1-1') + 'pts' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let assessment" class="clip">
            <app-context-menu
              *ngIf="
                isSelfUser(assessment) ||
                isDirectManager(assessment) ||
                isEnablerOrAdmin()
              "
              [menus]="this.contextMenu"
              (clicked)="contextMenuClick($event, assessment)"
            >
            </app-context-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="navigateToHome(row.user.id, row.id)"
        ></tr>
      </table>
      <app-loading [showLoading]="this.loading"></app-loading>
      <ng-container *ngIf="this.dataSource.length === 0 && !this.loading">
        <div class="empty">該当なし</div>
      </ng-container>
    </div>
  </div>
</div>
