import { CdkTrapFocus } from '@angular/cdk/a11y'
import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatIcon } from '@angular/material/icon'

import { tap } from 'rxjs'

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [CdkTrapFocus, ReactiveFormsModule, MatIcon, CommonModule],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Input() placeholder = ''
  @Input() width = '100%'
  @Input() iconSize = '18px'
  @Input() set value(val) {
    this.inputCtrl.setValue(val)
  }
  get value() {
    return this.inputCtrl.value
  }
  @Input() autoFocus = false
  @Output() changed = new EventEmitter<string>()
  @Output() enterPressed = new EventEmitter<string>()

  inputCtrl = new FormControl<string>('')

  ngOnInit(): void {
    this.inputCtrl.valueChanges
      .pipe(
        tap((value) => {
          this.changed.emit(value ?? '')
        }),
      )
      .subscribe()
  }

  clear() {
    this.inputCtrl.setValue('')
  }

  pressEnter(event: Event): void {
    if (event instanceof KeyboardEvent && event.isComposing === false) {
      this.enterPressed.emit(this.inputCtrl.value ?? '')
    }
  }

  iconStyle() {
    return {
      height: this.iconSize,
      width: this.iconSize,
      'font-size': this.iconSize,
      'line-height': this.iconSize,
    }
  }
}
