<div class="upload-list">
  <div class="summary">
    <mat-icon>check_circle</mat-icon>{{ completedItems.length }}/{{
      items.length
    }}件のアップロードが完了
    <button class="toggle-button" (click)="expanded = !expanded">
      <mat-icon *ngIf="!expanded">expand_more</mat-icon>
      <mat-icon *ngIf="expanded">expand_less</mat-icon>
    </button>
    <button class="close-button" (click)="this.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ul class="details" *ngIf="expanded">
    <li
      *ngFor="let item of items"
      class="detail"
      [class]="{
        completed: item.status === 'completed',
        error: item.status === 'error',
        'in-progress': item.status === 'in_progress',
      }"
    >
      <div class="left">
        <mat-icon *ngIf="item.status === 'completed'"
          >check_circle_outline</mat-icon
        >
        <mat-icon *ngIf="item.status === 'error'">error_outline</mat-icon>
        <mat-icon
          *ngIf="item.status === 'in_progress'"
          svgIcon="loading"
        ></mat-icon>
        <span>{{ item.name }}</span>
      </div>
      <div class="right">
        {{
          item.status === 'completed'
            ? '完了'
            : item.status === 'error'
              ? '失敗'
              : item.status === 'in_progress'
                ? '実行中'
                : ''
        }}
      </div>
    </li>
  </ul>
</div>
