<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <h1 class="heading">アセスメント一括実行</h1>
      <div class="actions">
        <button
          rectangle
          variant="secondary"
          [disabled]="this.dataSource.length === 0"
          (click)="onCsvImportButtonClicked()"
        >
          CSVからアップロード
        </button>
        <button rectangle (click)="onFileUploadButtonClicked()">
          <mat-icon>upload</mat-icon>
          ファイルをアップロード
        </button>
        <input
          type="file"
          #fileInput
          hidden
          multiple
          [accept]="this.acceptFileExtensionsForInput"
          (change)="onFileChange($event)"
        />
      </div>
    </div>
    <div class="sub-header">
      <div class="assessment-controls">
        <div class="form-field">
          <label class="label" for="skill-map">スキルマップ</label>
          <div class="value">
            <app-select
              id="skill-map"
              [options]="this.skillMapOptions"
              (selectedOptionChange)="this.changeSkillMap($event)"
              [selectedOption]="this.selectedSkillMap"
            ></app-select>
          </div>
        </div>
        <div class="form-field">
          <label class="label" for="applicable-phases">該当フェーズ：</label>
          <div class="value">
            <app-multiple-select
              id="applicable-phases"
              [items]="this.phaseItems"
              (changeSelected)="changeSelectedPhases($event)"
            ></app-multiple-select>
          </div>
        </div>
        <button
          rectangle
          [disabled]="
            this.dataSource.length === 0 || !skillMapAndPhaseSelected()
          "
          (click)="onExecuteButtonClicked()"
        >
          アセスメントを実行
        </button>
      </div>
      <span *ngIf="this.dataSource.length > 0" class="counter"
        >{{ dataSource.length }}件</span
      >
    </div>
    <div class="main" tabindex="-1">
      <ng-container *ngIf="this.dataSource.length === 0">
        <app-file-uploader
          [iconSize]="64"
          [multiple]="true"
          [acceptFileExtensions]="this.acceptFileExtensions"
          (uploaded)="onFileUploaded($event)"
        ></app-file-uploader>
      </ng-container>
      <ng-container *ngIf="this.dataSource.length > 0">
        <div
          class="table-container"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event)"
          [class.dragging]="isDragging"
        >
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>ファイル名</th>
              <td
                mat-cell
                *matCellDef="let meeting"
                [matTooltip]="meeting.fileName"
              >
                {{ meeting.fileName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>商談名</th>
              <td mat-cell *matCellDef="let meeting">
                <div class="field-with-error">
                  <input
                    class="field text-input"
                    [class.error]="
                      showErrors &&
                      hasBusinessMeetingNameError(meeting.fileName)
                    "
                    type="text"
                    placeholder="商談名を入力"
                    [value]="meeting.name"
                    (input)="onEditName(meeting, $event)"
                  />
                  <mat-icon
                    *ngIf="
                      showErrors &&
                      hasBusinessMeetingNameError(meeting.fileName)
                    "
                    class="error-icon"
                    [matTooltip]="'商談名は必須です'"
                  >
                    error
                  </mat-icon>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef>顧客名</th>
              <td mat-cell *matCellDef="let meeting">
                <input
                  class="field text-input"
                  type="text"
                  placeholder="顧客名を入力"
                  [value]="meeting.accountName"
                  (input)="onEditAccountName(meeting, $event)"
                />
              </td>
            </ng-container>
            <ng-container matColumnDef="fileDuration">
              <th mat-header-cell *matHeaderCellDef>記録期間</th>
              <td mat-cell *matCellDef="let meeting">
                {{ formatDuration(meeting.fileDuration) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="recordDate">
              <th mat-header-cell *matHeaderCellDef>記録日</th>
              <td mat-cell *matCellDef="let meeting">
                <app-date-input
                  id="record-date"
                  [date]="meeting.recordDate"
                  (dateChanged)="onEditRecordDate(meeting, $event)"
                ></app-date-input>
              </td>
            </ng-container>
            <ng-container matColumnDef="license">
              <th mat-header-cell *matHeaderCellDef>対象ユーザー</th>
              <td mat-cell *matCellDef="let meeting">
                <div class="field-with-error">
                  <app-select
                    class="field"
                    [options]="this.selectableLicenses"
                    [showError]="
                      showErrors && hasLicenseExceededError(meeting.fileName)
                    "
                    (selectedOptionChange)="onEditLicense(meeting, $event)"
                  ></app-select>
                  <mat-icon
                    *ngIf="
                      showErrors &&
                      this.hasLicenseExceededError(meeting.fileName)
                    "
                    class="error-icon"
                    [matTooltip]="'ライセンスの制限時間を超えています'"
                  >
                    error
                  </mat-icon>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let meeting" class="clip">
                <app-context-menu
                  [menus]="this.contextMenu"
                  (clicked)="contextMenuClick($event, meeting.fileName)"
                >
                </app-context-menu>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </ng-container>
    </div>
    <app-upload-list
      class="upload-list"
      *ngIf="showUploadList"
      [items]="this.uploadItems"
      (closed)="showUploadList = false"
    ></app-upload-list>
  </div>
</div>
