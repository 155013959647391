import { ApplicationConfig, inject } from '@angular/core'
import { getAuth, getIdToken } from '@angular/fire/auth'

import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'

import { environment } from '../environments/environment'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink)

  const auth = setContext(async () => {
    const user = getAuth().currentUser
    if (user) {
      const token = await getIdToken(user, true)
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    }
    return null
  })

  return {
    link: ApolloLink.from([
      auth,
      httpLink.create({ uri: environment.graphqlUrl }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none',
      },
    },
  }
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
]
