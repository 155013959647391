<div class="container">
  <div class="overall" [class.collapsed]="this.collapsed">
    <button class="toggle-button" (click)="this.collapsed = !this.collapsed">
      <mat-icon>
        {{ this.collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}
      </mat-icon>
    </button>
    <div
      class="collapsable"
      [style.visibility]="this.collapsed ? 'hidden' : 'visible'"
    >
      <div class="user">
        <div class="avatar-wrapper">
          <app-avatar
            [thumbnailUrl]="this.user?.thumbnailPath ?? ''"
          ></app-avatar>
        </div>
        <div class="name">{{ this.user?.name }}</div>
        <div class="department">{{ this.user?.departmentName }}</div>
      </div>
      <app-loading
        [showLoading]="
          !!this.assessment && this.assessment.status === 'IN_PROGRESS'
        "
      ></app-loading>
      <ng-container
        *ngIf="this.assessment && this.assessment.status === 'COMPLETED'"
      >
        <div class="score-wrapper">
          <div class="title">今回の商談のスコア</div>
          <div class="score">{{ this.score | number: '1.2-2' }}</div>
        </div>
        <div class="widget">
          <div class="high-light card">
            <div class="title">
              <div class="label">
                <div class="badge">TOP 3</div>
                <div class="text">良かった点</div>
              </div>
            </div>
            <div class="list">
              <ng-container *ngFor="let highLight of this.highLights">
                <div class="item">
                  <div class="key-action">
                    <div class="badge">
                      {{ highLight.keyAction.alias
                      }}<span class="circle green">●</span>
                    </div>
                    <div class="text" [matTooltip]="highLight.keyAction.name">
                      {{ highLight.keyAction.name }}
                    </div>
                  </div>
                  <div class="score">
                    {{ highLight.score | number: '1.2-2' }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="this.highLights.length === 0">
                <div class="empty">該当なし</div>
              </ng-container>
            </div>
          </div>
          <div class="low-light card">
            <div class="title">
              <div class="label">
                <div class="badge">TOP 3</div>
                <div class="text">改善したい点</div>
              </div>
            </div>
            <div class="list">
              <ng-container *ngFor="let lowLight of this.lowLights">
                <div class="item">
                  <div class="key-action">
                    <div class="badge">
                      {{ lowLight.keyAction.alias
                      }}<span class="circle orange">●</span>
                    </div>
                    <div class="text" [matTooltip]="lowLight.keyAction.name">
                      {{ lowLight.keyAction.name }}
                    </div>
                  </div>
                  <div class="score">
                    {{ lowLight.score | number: '1.2-2' }}
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="this.lowLights.length === 0">
                <div class="empty">該当なし</div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="main">
    <div class="main-header">
      <div class="left">
        <div class="select-wrapper">
          <span class="select-label">商談</span>
          <app-select
            (selectedOptionChange)="this.changeAssessment($event)"
            [options]="this.assessments"
            [selectedOption]="this.selectedAssessment"
          ></app-select>
        </div>
        <button
          rectangle
          variant="secondary"
          class="register-info"
          (click)="this.showRegisterInformation()"
          *ngIf="!this.isNotSet()"
        >
          詳細
        </button>
        <ng-container *ngIf="this.assessment">
          <button
            mat-icon-button
            class="delete-button"
            (click)="this.deleteAssessment(this.assessment)"
            [disabled]="
              !this.isSelfUser() &&
              !this.isDirectManager() &&
              !this.isEnablerOrAdmin()
            "
          >
            <mat-icon color="secondary">delete</mat-icon>
          </button>
        </ng-container>
      </div>
      <div class="upload-button-wrapper">
        <button
          rectangle
          (click)="this.startUploadingFile()"
          [disabled]="
            (!this.isSelfUser() &&
              !this.isDirectManager() &&
              !this.isEnablerOrAdmin()) ||
            !this.hasLicense()
          "
        >
          <mat-icon>upload</mat-icon>
          ファイルをアップロード
        </button>
      </div>
    </div>
    <div *ngIf="!!this.assessment" class="result">
      <div *ngIf="this.assessment.status === 'FAILED'">
        <strong>アセスメント中にエラーが発生しました</strong>
        <p>
          アップロードしたファイルに問題ないかご確認のうえ、再度お試しください。
          改善しない場合は開発チームまでご連絡お願いします。
        </p>
      </div>
      <div *ngIf="this.assessment.status !== 'FAILED'" class="content">
        <div class="content-body">
          <div class="key-action-filter">
            <ng-container *ngFor="let evaluation of this.keyActionEvaluations">
              <button
                rectangle
                [variant]="
                  this.selectedKeyAction?.id === evaluation.id
                    ? 'primary'
                    : 'secondary'
                "
                class="key-action-button"
                (click)="this.selectKeyAction(evaluation.id)"
              >
                <div
                  class="key-action"
                  [ngClass]="{
                    active: this.selectedKeyAction?.id === evaluation.id,
                  }"
                >
                  {{ evaluation.keyAction.alias
                  }}<span
                    class="circle"
                    [class]="{
                      green: isHighlight(evaluation),
                      orange: isLowLight(evaluation),
                    }"
                    >●</span
                  >
                </div>
              </button>
            </ng-container>
          </div>
          <div class="evaluation-wrapper">
            <div class="evaluation-section">
              <div class="evaluation">
                <div class="key-action">
                  <div class="key-action-title">
                    <div class="key-action-label">
                      {{ this.selectedKeyAction?.keyAction?.alias }}
                    </div>
                    <div class="key-action-name">
                      {{ this.selectedKeyAction?.keyAction?.name }}
                    </div>
                  </div>
                  <div
                    class="key-action-score"
                    *ngIf="!!this.selectedKeyAction?.score"
                  >
                    {{ this.selectedKeyAction?.score | number: '1.2-2' }}
                  </div>
                </div>
                <div class="evaluation-content" #evaluation>
                  <div class="badge">アセスメント評価</div>
                  <div
                    class="evaluation-text"
                    [innerHTML]="
                      parseEvaluation(this.selectedKeyAction?.evaluation)
                    "
                  ></div>
                  <div class="recommendation">
                    <div class="header">
                      <div class="badge">おすすめコンテンツ</div>
                    </div>
                    <div class="recommendation-content-wrapper">
                      <ng-container
                        *ngFor="
                          let recommendation of this.selectedKeyAction
                            ?.recommendedContents
                        "
                      >
                        <div class="recommendation-content">
                          <div class="content">
                            <a
                              class="content-link"
                              [href]="recommendation.content.url"
                              (click)="
                                this.markContentAsAccessed(recommendation.id)
                              "
                              target="_blank"
                              rel="noopener noreferrer"
                              >{{ recommendation.content.name }}</a
                            >
                            <mat-icon class="icon">{{
                              recommendation.isAccessed
                                ? 'check_circle_filled'
                                : 'play_circle_outline'
                            }}</mat-icon>
                          </div>
                        </div>
                      </ng-container>
                      <span
                        *ngIf="
                          this.selectedKeyAction?.recommendedContents
                            ?.length === 0
                        "
                        >該当なし</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="transcript-section">
              <div
                class="media-space"
                *ngIf="
                  this.isMediaFile() && this.assessment.status === 'COMPLETED'
                "
              >
                <audio
                  *ngIf="this.downloadUrl"
                  controls
                  #audioPlayer
                  preload="none"
                >
                  <source
                    src="{{ this.downloadUrl }}"
                    type="{{ this.getMimeType(this.downloadUrl) }}"
                  />
                </audio>
              </div>
              <div class="transcript">
                <div class="transcript-title">文字起こし</div>
                <div class="transcript-content" tabindex="-1" #transcript>
                  <ng-container
                    *ngFor="
                      let speak of this.assessment?.businessMeeting?.transcript
                    "
                  >
                    <div class="speak">
                      <button
                        [ngClass]="{ 'cursor-default': !this.isMediaFile() }"
                        class="speak-info"
                        (click)="this.seekTo(speak.speakedAt)"
                        [tabIndex]="this.isMediaFile() ? 0 : -1"
                        [ariaDisabled]="!this.isMediaFile()"
                      >
                        <div class="speaker">{{ speak.speaker }}</div>
                        <div class="speaked-at">{{ speak.speakedAt }}</div>
                        <div class="audio" *ngIf="this.isMediaFile()">
                          <mat-icon>volume_up</mat-icon>
                        </div>
                      </button>
                      <div class="quote">{{ speak.quote }}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-loading
        [showLoading]="
          !!this.assessment && this.assessment.status === 'IN_PROGRESS'
        "
        [zIndex]="999"
      ></app-loading>
      <div
        class="custom-loader-wrapper"
        *ngIf="this.assessment?.status === 'IN_PROGRESS'"
        aria-busy="true"
      >
        <div>
          <div class="custom-loader"></div>
          <div class="custom-loader-title">アセスメント実行中...</div>
          <div class="custom-loader-description">
            完了には時間がかかります。時間をおいて、再度ご確認ください。
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
