import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms'

export function invalidStringValidator(validRegex: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }
    const valid = validRegex.test(control.value)
    return !valid ? { invalidString: { value: control.value } } : null
  }
}

export function requiredArrayValidator(): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value
    return Array.isArray(value) && value.length > 0 ? null : { required: true }
  }
}
