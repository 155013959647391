<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon>error_outline</mat-icon>
      入力内容にエラーがあります
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <p>
      入力内容に誤りがあります。表示されているエラーを確認してから、再度実行してください。
    </p>
    <div class="footer">
      <button rectangle variant="primary" (click)="this.close()">OK</button>
    </div>
  </div>
</div>
