import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { MatIcon } from '@angular/material/icon'
import { MatSnackBar } from '@angular/material/snack-bar'

import { tap, catchError, of } from 'rxjs'

import { MessageDialogService } from '../../../components/message-dialog/message-dialog.service'
import {
  UserService,
  PrivacySettingType,
} from '../../../services/user/user.service'
import { getTitle } from '../../../util/accessibility'
interface PrivacySettingOption {
  type: PrivacySettingType
  title: string
  description: string
}

@Component({
  selector: 'app-privacy-setting-page',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './privacy-setting-page.component.html',
  styleUrl: './privacy-setting-page.component.scss',
})
export class PrivacySettingPageComponent implements OnInit {
  privacySettingOptions: PrivacySettingOption[] = [
    {
      type: 'FULLY_OPEN',
      title: 'Fully Open',
      description: '全ユーザーが全ユーザーのデータを見ることが可能です。',
    },
    {
      type: 'PARTIALLY_OPEN',
      title: 'Partially Open',
      description:
        '自分のデータ、自分のマネジメントチェーン、直属の上司を共有しているユーザー、直属の上司を共有しているユーザーのマネジメントチェーンを見ることが可能です。',
    },
    {
      type: 'FULLY_CLOSED',
      title: 'Fully Closed',
      description:
        '全ユーザーが、自分と自分の部下のデータのみ見ることが可能です。',
    },
  ]
  selectedPrivacySetting: PrivacySettingType | undefined = undefined

  constructor(
    private messageDialogService: MessageDialogService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('プライバシー設定'))
  }

  ngOnInit(): void {
    this.userService
      .getAdminOrg()
      .pipe(
        tap((adminOrg) => {
          this.selectedPrivacySetting = adminOrg.privacySetting
        }),
      )
      .subscribe()
  }

  onPrivacySettingChange(setting: PrivacySettingType): void {
    if (setting === this.selectedPrivacySetting) {
      return
    }

    this.selectedPrivacySetting = setting
    this.userService
      .updatePrivacySetting(setting)
      .pipe(
        tap(() => {
          this.snackBar.open('プライバシー設定を更新しました。', 'close', {
            duration: 3000,
          })
        }),
        catchError(() => {
          this.messageDialogService.showError(
            'プライバシー設定の更新に失敗しました。時間を置いて再度お試しください。',
            {
              title: 'エラー',
            },
          )
          return of()
        }),
      )
      .subscribe()
  }
}
