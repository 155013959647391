import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatTooltip } from '@angular/material/tooltip'

export interface Option {
  id: string
  name: string
  isDefault?: boolean
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  standalone: true,
  imports: [
    MatAutocompleteTrigger,
    MatInputModule,
    CommonModule,
    FormsModule,
    MatTooltip,
    MatAutocomplete,
    MatFormFieldModule,
    MatOption,
  ],
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger:
    | MatAutocompleteTrigger
    | undefined

  @Input() set options(value: Option[]) {
    this._options = value
    if (this._selectedOption) {
      const selectedOption = value?.find(
        (option) => option.id === this._selectedOption?.id,
      )
      if (selectedOption) {
        this.displayValue = selectedOption.name
      }
    } else {
      const defaultOption = value?.find((option) => option.isDefault)
      if (defaultOption) {
        this.displayValue = defaultOption.name
      }
    }
  }
  get options(): Option[] {
    return this._options
  }
  private _options: Option[] = []

  @Input() set selectedOption(value: Option) {
    this._selectedOption = value
    this.displayValue = value?.name
  }
  private _selectedOption: Option | null = null

  @Input() disabled = false
  @Input() showError = false
  @Output() selectedOptionChange = new EventEmitter<Option>()

  displayValue: string | null = null
  displayFn = () => this.displayValue ?? ''

  onInputClick(): void {
    if (!this.disabled) {
      this.autoCompleteTrigger?.openPanel()
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this._selectedOption = event.option.value
    this.displayValue = event.option.value.name
    this.selectedOptionChange.emit(event.option.value)
  }
}
