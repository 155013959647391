<div class="container">
  <button
    type="button"
    mat-icon-button
    class="close-button"
    (click)="this.close()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="header">
    <h1 class="title">商談ファイルのアップロード</h1>
    <caption>
      残りアップロード時間:
      {{
        remainsHours()
      }}時間
    </caption>
  </div>
  <div class="help">
    <a
      class="link"
      target="_blank"
      href="https://storage.googleapis.com/xp-gpt-prod-public-contents/help/file_upload.pdf"
      >対応する書き起こしテキストについて<mat-icon
        svgIcon="arrow-right"
      ></mat-icon
    ></a>
  </div>
  <div class="file-information-details">
    <app-file-uploader
      [acceptFileExtensions]="this.acceptFileExtensions"
      (uploaded)="this.onFileUploaded($event)"
    ></app-file-uploader>
    <div class="file-detail">
      <mat-icon>{{
        isAudioFile(this.file) ? 'music_video' : 'attachment'
      }}</mat-icon>
      <div class="view-file">
        <span
          class="input-file-label-wrapper"
          [matTooltip]="this.file?.name ?? ''"
        >
          <span class="file-name">
            <span class="file-name-text">{{
              this.file?.name ?? 'ファイル未設定'
            }}</span>
          </span>
          <span class="file-size">{{
            this.getFileSizeWithUnit(this.file?.size || 0)
          }}</span>
        </span>
      </div>
    </div>
    <div class="detail">
      <label class="label" for="business-meeting-name">商談名：</label>
      <div class="value">
        <input
          id="business-meeting-name"
          class="input-control"
          placeholder="商談名を入力"
          [formControl]="this.businessMeetingName"
        />
        <!-- <div
          class="input-error"
          *ngIf="
            this.businessMeetingName.touched &&
            this.businessMeetingName.hasError('required')
          "
        >
          商談名は必須です
        </div> -->
      </div>
    </div>
    <div class="detail">
      <label class="label" for="account-name">顧客名（任意）：</label>
      <div class="value">
        <input
          id="account-name"
          class="input-control"
          placeholder="会社名を入力"
          [formControl]="this.accountName"
          [matAutocomplete]="account_name_auto"
        />
        <mat-autocomplete #account_name_auto="matAutocomplete">
          <mat-option
            *ngFor="let account of filteredAccounts | async"
            [value]="account.name"
          >
            {{ account.name }}
          </mat-option>
        </mat-autocomplete>
        <!-- <div
          class="input-error"
          *ngIf="
            this.accountName.touched && this.accountName.hasError('required')
          "
        >
          顧客名は必須です
        </div> -->
      </div>
    </div>
    <div class="detail">
      <span class="label">スキルマップ：</span>
      <div class="value">
        <app-select
          [id]="'skill-map'"
          [options]="this.skillMapOptions"
          (selectedOptionChange)="this.changeSkillMap($event)"
          [selectedOption]="this.selectedSkillMap"
        ></app-select>
      </div>
    </div>
    <div class="detail">
      <span class="label">該当フェーズ：</span>
      <div class="value">
        <app-multiple-select
          [id]="'applicable-phases'"
          [items]="this.phaseItems"
          (changeSelected)="changeSelectedPhases($event)"
        ></app-multiple-select>
      </div>
    </div>
    <div class="detail">
      <label class="label" for="record-date">記録日：</label>
      <div class="value">
        <app-date-input
          class="date-input"
          [id]="'record-date'"
          [inputDate]="this.recordDate"
        ></app-date-input>
        <!-- <div
          class="input-error"
          *ngIf="
            this.recordDate.touched && this.recordDate.hasError('required')
          "
        >
          記録日は必須です
        </div> -->
      </div>
    </div>
    <div class="detail">
      <div class="label">記録期間：</div>
      <div class="value text">{{ this.formatDuration(this.fileDuration) }}</div>
    </div>
  </div>
  <div class="action-buttons">
    <button
      class="cancel"
      rectangle
      variant="secondary"
      [disabled]="this.submitted"
      (click)="this.close()"
    >
      キャンセル
    </button>
    <button
      class="execute-assessment"
      rectangle
      [disabled]="!this.inputValid || this.submitted"
      (click)="this.executeAssessment()"
    >
      保存
    </button>
  </div>
  <app-loading [showLoading]="this.submitted"></app-loading>
</div>
