import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner'
import { v4 as uuid } from 'uuid'

@Component({
  selector: 'app-loading',
  template: `<ngx-spinner
    [name]="this.spinnerId"
    [fullScreen]="this.fullScreen"
    [disableAnimation]="true"
    type="line-scale"
    [size]="this.size"
    bdColor="rgba(128, 128, 128, 0.1)"
    color="#999999"
    [zIndex]="this.zIndex"
  >
  </ngx-spinner>`,
  standalone: true,
  imports: [NgxSpinnerComponent, CommonModule, MatIcon],
})
export class LoadingComponent {
  @Input() set showLoading(value: boolean) {
    value
      ? this.spinner.show(this.spinnerId)
      : this.spinner.hide(this.spinnerId)
  }

  @Input()
  set fullScreen(value: BooleanInput) {
    this._fullScreen = coerceBooleanProperty(value)
  }
  get fullScreen(): boolean {
    return this._fullScreen
  }

  @Input()
  set size(value: NgxSpinnerComponent['size']) {
    this._size = value
  }
  get size(): NgxSpinnerComponent['size'] {
    return this._size
  }
  @Input() zIndex = 99999

  spinnerId = uuid()

  private _fullScreen = false
  private _size: NgxSpinnerComponent['size'] = 'default'

  constructor(private spinner: NgxSpinnerService) {}
}
