import { Injectable } from '@angular/core'

import { Observable, map } from 'rxjs'

import { mappingUserLicense, License } from './license.mapping'
import { GetLicensesGQL } from '../../../_generated/graphql'

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  constructor(private getLicensesGQL: GetLicensesGQL) {}

  searchLicenses(yearMonth?: string): Observable<License[]> {
    return this.getLicensesGQL.fetch({ yearMonth }).pipe(
      map((result) => {
        return result.data.getLicenses.map(mappingUserLicense)
      }),
    )
  }
}
