<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <h1 class="heading">ライセンス一覧</h1>
    </div>
    <div class="sub-header">
      <div class="select">
        <app-month-year-input
          [date]="this.selectedDate"
          (dateChange)="this.onDateChange($event)"
        ></app-month-year-input>
      </div>
      <div class="year-month">
        {{ formatMonthYearRange(this.selectedDate) }}
      </div>
    </div>
    <div class="table-container" tabindex="-1">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>名前</th>
          <td mat-cell *matCellDef="let user">
            <div class="user-info">
              <div class="avatar">
                <app-avatar
                  [thumbnailUrl]="user.thumbnailPath ?? ''"
                ></app-avatar>
              </div>
              <div class="user-name-email">
                <div class="name">{{ user.name ?? '未アサイン' }}</div>
                <div class="email">{{ user.email }}</div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>部署</th>
          <td mat-cell *matCellDef="let user">{{ user.department }}</td>
        </ng-container>

        <ng-container matColumnDef="reportTo">
          <th mat-header-cell *matHeaderCellDef>上司</th>
          <td mat-cell *matCellDef="let user">{{ user.reportTo }}</td>
        </ng-container>

        <ng-container matColumnDef="businessMeetingCount">
          <th mat-header-cell *matHeaderCellDef>商談回数</th>
          <td mat-cell *matCellDef="let user">
            {{ user.businessMeetingCount }}件
          </td>
        </ng-container>

        <ng-container matColumnDef="licenseUsage">
          <th mat-header-cell *matHeaderCellDef>利用状況</th>
          <td mat-cell *matCellDef="let user">
            <div class="license-usage">
              <div class="label">
                {{ formatHoursAndMinutes(user.usedSeconds) }}
              </div>
              <div class="usage-ratio-wrapper">
                <div class="usage-ratio">
                  <div
                    class="usage-bar"
                    [style.width]="
                      this.calculateUsagePercentage(
                        user.usedSeconds,
                        user.limitSeconds
                      ) + '%'
                    "
                  ></div>
                </div>
                <div class="label">{{ formatHours(user.limitSeconds) }}</div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="extraChargeCount">
          <th mat-header-cell *matHeaderCellDef>追加購入</th>
          <td mat-cell *matCellDef="let user">
            <span class="chip"> {{ user.extraChargeCount }}回 </span>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <app-loading [showLoading]="this.isLoading"> </app-loading>
    </div>
  </div>
</div>
