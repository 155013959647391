<button
  type="button"
  #menuTrigger
  mat-icon-button
  class="operation-button"
  (click)="this.onMenuTriggerClick($event, menuTrigger)"
>
  <mat-icon>more_horiz</mat-icon>
</button>

<ng-template #contextMenu>
  <div class="context-menu">
    <ng-container *ngFor="let menu of this.menus">
      <button
        class="menu-item"
        [ngClass]="{ disable: menu.disable }"
        (click)="this.onMenuClick(menu)"
      >
        <mat-icon class="menu-icon material-icons-outlined">
          {{ menu.icon }}
        </mat-icon>
        <div>
          {{ menu.title }}
        </div>
      </button>
    </ng-container>
  </div>
</ng-template>
