import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

@Component({
  selector: 'app-validation-error',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent {
  constructor(private dialogRef: MatDialogRef<ValidationErrorComponent>) {}

  close(): void {
    this.dialogRef.close()
  }
}
