<div class="wrapper">
  <div class="container">
    <div class="page-header">
      <div class="card user-attribute">
        <app-avatar [thumbnailUrl]="user?.thumbnailPath ?? ''"></app-avatar>
        <div class="name-plate">
          <div class="name">{{ this.user?.name }}</div>
          <div class="department">{{ this.user?.departmentName }}</div>
        </div>
      </div>
      <div class="user-score card">
        <div class="user-score-header">
          <div class="title">最新総合スコア</div>
          <div class="between">
            集計期間：{{ this.startDate | date: 'yyyy/MM/dd' }} ~
            {{ this.endDate | date: 'yyyy/MM/dd' }}
          </div>
        </div>
        <div class="score">{{ this.latestAverageScore | number: '1.2-2' }}</div>
      </div>
    </div>
    <div class="score-transition card">
      <div class="score-transition-header">
        <div class="score-transition-title">キーアクション別スコア推移</div>
        <div class="right-section">
          <div class="view-range">
            <span class="label">表示期間</span>
            <div class="value">
              <app-select
                [id]="'view-range'"
                [options]="this.rangeOptions"
                [selectedOption]="this.selectedRange"
                (selectedOptionChange)="this.onRangeChange($event)"
              ></app-select>
            </div>
          </div>
        </div>
      </div>
      <div class="score-transition-chart">
        <app-time-series-chart [data]="this.data"></app-time-series-chart>
        <app-loading [showLoading]="this.isLoading"></app-loading>
      </div>
    </div>
  </div>
</div>
