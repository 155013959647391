import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { MatIcon } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'

import moment from 'moment'
import { Subject, tap, finalize } from 'rxjs'

import { AvatarComponent } from '../../../components/avatar/avatar.component'
import { ButtonComponent } from '../../../components/button/button.component'
import { LoadingComponent } from '../../../components/loading/loading.component'
import { MonthYearInputComponent } from '../../../components/year-month-input/month-year-input.component'
import { License } from '../../../services/license/license.mapping'
import { LicenseService } from '../../../services/license/license.service'
import { getTitle } from '../../../util/accessibility'
interface LicenseRow {
  name?: string
  email?: string
  thumbnailPath?: string
  department?: string
  reportTo?: string
  businessMeetingCount: number
  usedSeconds: number
  limitSeconds: number
  extraChargeCount: number
}

@Component({
  selector: 'app-license-usage-page',
  standalone: true,
  imports: [
    ButtonComponent,
    MatIcon,
    MatTableModule,
    AvatarComponent,
    MonthYearInputComponent,
    LoadingComponent,
  ],
  templateUrl: './license-usage-page.component.html',
  styleUrl: './license-usage-page.component.scss',
})
export class LicenseUsagePageComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'department',
    'reportTo',
    'businessMeetingCount',
    'licenseUsage',
    'extraChargeCount',
  ]
  dataSource: LicenseRow[] = []
  selectedDate = new Date()
  selectedDate$ = new Subject<Date>()
  isLoading = false

  constructor(
    private licenseService: LicenseService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('ライセンス一覧'))
  }

  ngOnInit(): void {
    this.selectedDate$.subscribe((date) => {
      this.isLoading = true
      this.licenseService
        .searchLicenses(moment(date).format('YYYYMM'))
        .pipe(
          tap((licenses) => {
            this.dataSource = licenses.map(this.mappingToRow)
          }),
          finalize(() => {
            this.isLoading = false
          }),
        )
        .subscribe()
    })
    this.selectedDate$.next(this.selectedDate)
  }

  onDateChange(date: Date): void {
    this.selectedDate = date
    this.selectedDate$.next(date)
  }

  formatHours(seconds: number): string {
    return `${Math.floor(seconds / 3600)}時間`
  }

  formatHoursAndMinutes(seconds: number): string {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    return `${hours}時間${minutes}分`
  }

  formatMonthYearRange(date: Date): string {
    const start = moment(date).startOf('month').format('YYYY/MM/DD')
    const end = moment(date).endOf('month').format('YYYY/MM/DD')
    return `${start} - ${end}`
  }

  calculateUsagePercentage(usedSeconds: number, limitSeconds: number): number {
    if (limitSeconds === 0) {
      return 0
    }

    return Math.min(Math.ceil((usedSeconds / limitSeconds) * 100), 100)
  }

  private mappingToRow(license: License): LicenseRow {
    return {
      name: license.user?.username,
      email: license.user?.email,
      thumbnailPath: license.user?.thumbnailPath || undefined,
      department: license.user?.departmentName || undefined,
      reportTo: license.user?.reportTo || undefined,
      businessMeetingCount: license.businessMeetingCount,
      usedSeconds: license.usedSeconds,
      limitSeconds: license.limitSeconds,
      extraChargeCount: license.extraChargeCount,
    }
  }
}
