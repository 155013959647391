import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { MatIcon } from '@angular/material/icon'

export interface ContextMenu {
  icon: string
  title: string
  type: string
  disable?: boolean
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  standalone: true,
  imports: [MatIcon, CommonModule],
})
export class ContextMenuComponent {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  @ViewChild('contextMenu', { read: TemplateRef }) private contextMenu: any
  @Input() menus: ContextMenu[] = []
  @Output() clicked = new EventEmitter<string>()

  private overlayRef?: OverlayRef = undefined

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {}

  onMenuTriggerClick(e: Event, button: HTMLElement): void {
    e.preventDefault()
    e.stopPropagation()
    this.showContextMenu(button)
  }

  onMenuClick(menu: ContextMenu): void {
    if (!menu.disable) {
      this.detachContextMenu()
      this.clicked.emit(menu.type)
    }
  }

  private showContextMenu(connectedToElem: HTMLElement): void {
    if (this.overlayRef) {
      this.detachContextMenu()
    }

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: '',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectedToElem)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
    })
    this.overlayRef.backdropClick().subscribe(() => this.detachContextMenu())

    const portal = new TemplatePortal(this.contextMenu, this.viewContainerRef)
    this.overlayRef.attach(portal)
  }

  private detachContextMenu(): void {
    if (this.overlayRef) {
      this.overlayRef.detach()
      this.overlayRef.dispose()
      this.overlayRef = undefined
    }
  }
}
