const SUPPORTED_FILE_SIZE = 1024 * 1024 * 1024 // 1GB

export const SUPPORTED_TEXT_EXTENSIONS = ['txt']

export const SUPPORTED_AUDIO_EXTENSIONS = ['mp3', 'mp4', 'aac', 'wav', 'm4a']

export const getMimeType = (extension: string) => {
  switch (extension) {
    case 'mp3':
      return 'audio/mpeg'
    case 'mp4':
      return 'audio/mp4'
    case 'aac':
      return 'audio/aac'
    case 'wav':
      return 'audio/wav'
    case 'flac':
      return 'audio/flac'
    case 'm4a':
      return 'audio/x-m4a'
    case 'webm':
      return 'audio/webm'
    default:
      throw new Error('Unsupported audio extension')
  }
}

export const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result as string)
      } else {
        reject(new Error('Failed to read file'))
      }
    }
    reader.onerror = () => {
      reject(new Error('Failed to read file'))
    }
    reader.readAsText(file)
  })
}

export const isAudioFile = (file: File): boolean => {
  return SUPPORTED_AUDIO_EXTENSIONS.some((ext) =>
    file.name.toLowerCase().endsWith(`.${ext.toLocaleLowerCase()}`),
  )
}

export const isValidFileExtension = (
  file: File,
  supportedExtensions: string[],
): boolean => {
  return supportedExtensions.some((ext) =>
    file.name.toLowerCase().endsWith(`.${ext.toLocaleLowerCase()}`),
  )
}

export const isValidFileSize = (file: File): boolean => {
  return file.size <= SUPPORTED_FILE_SIZE
}

export const calculateAudioDuration = (file: File): Promise<number | null> => {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file)
    const mediaElement = document.createElement(
      file.type.startsWith('audio') ? 'audio' : 'video',
    )
    mediaElement.src = url
    mediaElement.addEventListener('loadedmetadata', function () {
      const duration = Math.ceil(mediaElement.duration)
      resolve(duration)
      URL.revokeObjectURL(url)
    })

    mediaElement.addEventListener('error', function () {
      resolve(null)
      URL.revokeObjectURL(url)
    })
  })
}

export const calculateZoomTextDuration = (transcript: string): number => {
  const lines = transcript.split('\n')
  const timeRegex = /\d{2}:\d{2}:\d{2}/

  // 合計時間を計算
  const times = lines
    .map((line) => {
      const match = line.match(timeRegex)
      if (match) {
        const [hours, minutes, seconds] = match[0].split(':').map(Number)
        return hours * 60 * 60 + minutes * 60 + seconds
      }
      return null
    })
    .filter((time) => time !== null) as number[]

  if (times.length <= 1) return 0

  return times[times.length - 1] - times[0]
}

export const validateZoomTranscriptFormat = (transcript: string) => {
  const lines = transcript.split('\n').filter((line) => line.trim() !== '')
  const regexSpeakerLine = /^\[([^\]]+)\] \d{2}:\d{2}:\d{2}$/

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim()

    if (i % 2 === 0) {
      if (!regexSpeakerLine.test(line)) {
        return false
      }

      const parts = line.split(' ')
      const timestamp = parts[parts.length - 1]
      const [hours, minutes, seconds] = timestamp.split(':').map(Number)

      if (
        hours < 0 ||
        hours > 23 ||
        minutes < 0 ||
        minutes > 59 ||
        seconds < 0 ||
        seconds > 59
      ) {
        return false
      }
    } else {
      if (!line) {
        return false
      }
    }
  }

  return true
}
