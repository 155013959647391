<div class="header-left">
  <div class="logo">
    <img
      class="xp-icon"
      alt="Xpotential icon"
      src="assets/icons/xpotential-icon.svg"
    />
    <div class="name">AI Sales Coach</div>
  </div>
</div>
<div class="header-right">
  <div class="menu">
    <a
      class="item"
      [ngClass]="{ active: this.currentPage === 'HOME' }"
      [routerLink]="['/home']"
      >ホーム</a
    >
    <a
      class="item"
      *ngIf="hasMember() || canSeeMemberInfo()"
      [ngClass]="{ active: this.currentPage === 'USER_LIST' }"
      [routerLink]="['/users']"
      >メンバー</a
    >
    <button
      class="item"
      *ngIf="isEnablerOrAdmin()"
      [matMenuTriggerFor]="assessmentMenu"
      [ngClass]="{ active: this.currentPage === 'ASSESSMENTS' }"
    >
      アセスメント
    </button>
    <mat-menu #assessmentMenu="matMenu">
      <a class="sub-menu-item" [routerLink]="['/assessments']"
        >アセスメント一覧</a
      >
      <a class="sub-menu-item" [routerLink]="['/assessments', 'bulk-execution']"
        >アセスメント一括実行</a
      >
    </mat-menu>
    <button
      class="item"
      *ngIf="isEnablerOrAdmin()"
      [matMenuTriggerFor]="adminMenu"
      [ngClass]="{ active: this.currentPage === 'ADMIN' }"
    >
      組織設定
    </button>
    <mat-menu #adminMenu="matMenu">
      <a class="sub-menu-item" [routerLink]="['/admin', 'users']"
        >ユーザー管理</a
      >
      <a class="sub-menu-item" [routerLink]="['/admin', 'license-usage']"
        >利用状況</a
      >
      <a
        class="sub-menu-item"
        *ngIf="this.isAdmin()"
        [routerLink]="['/admin', 'privacy-setting']"
        >プライバシー設定</a
      >
    </mat-menu>
  </div>
  <div class="user-wrapper">
    <div class="user">
      <div class="name">{{ user?.name }}</div>
      <div class="department">{{ user?.departmentName }}</div>
    </div>
    <button class="icon-button" [matMenuTriggerFor]="userMenu">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
      <button class="sub-menu-item" (click)="logout()">ログアウト</button>
    </mat-menu>
  </div>
</div>
