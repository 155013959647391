import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core'

const BUTTON_HOST_ATTRIBUTES = ['rectangle']

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[rectangle]',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() variant: 'primary' | 'secondary' = 'primary'

  constructor(private _elementRef: ElementRef) {
    const hostElement = this.getHostElement() as HTMLElement
    hostElement.classList.add('button')
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        hostElement.classList.add(attr)
      }
    }
  }

  ngOnInit() {
    this.applyTypeClass()
  }

  ngOnChanges() {
    this.applyTypeClass()
  }

  private getHostElement() {
    return this._elementRef.nativeElement
  }

  private hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) =>
      this.getHostElement().hasAttribute(attribute),
    )
  }

  private applyTypeClass() {
    const hostElement = this.getHostElement() as HTMLElement
    hostElement.classList.remove('primary', 'secondary')
    hostElement.classList.add(this.variant)
  }
}
