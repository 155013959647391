<div class="search-input-component">
  <div class="search-input-wrapper">
    <input
      [cdkTrapFocus]="autoFocus"
      [cdkTrapFocusAutoCapture]="autoFocus"
      class="search-input"
      type="text"
      [formControl]="inputCtrl"
      [placeholder]="placeholder"
      (keydown.enter)="pressEnter($event)"
    />
  </div>
  <ng-container *ngIf="!value || value?.length === 0">
    <mat-icon class="icon" [style]="iconStyle()">search</mat-icon>
  </ng-container>
  <ng-container *ngIf="!!value?.length">
    <button class="clear-button" (click)="clear()">
      <mat-icon class="icon" [style]="iconStyle()">close</mat-icon>
    </button>
  </ng-container>
</div>
