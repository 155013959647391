<div class="container">
  <h1><mat-icon>error_outline</mat-icon>ライセンスエラーが発生しました</h1>
  <p>
    このシステムを利用する際にはライセンスが付与されている必要があります。以下の手順に従って、ライセンスを更新してください。
  </p>

  <div class="instructions">
    <h2>推奨手順</h2>
    <ol>
      <li>
        <strong>ライセンス付与を依頼する</strong>
        <p>組織の全件管理者にライセンスの付与を依頼してください。</p>
      </li>
      <li>
        <strong>再度ログインする</strong>
        <p>
          ライセンス付与後に下記リンクよりログイン画面に移動し、再度ログインをお試しください。
        </p>
      </li>
    </ol>
  </div>

  <div class="navigation">
    <a href="/login"> ログイン画面へ </a>
  </div>

  <p class="footer">
    それでも解決しない場合は、サポートチームにお問い合わせください。
  </p>
</div>
