<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon class="success">check_circle_outline</mat-icon
      >アセスメントの実行が成功しました！
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <div class="success-message">
      <p class="strong">
        アセスメントが開始されました。結果を確認できるまでしばらくお待ちください。
      </p>
      <p>
        結果は<a class="strong" [routerLink]="['/assessments']"
          >「アセスメント一覧」ページ</a
        >で確認できます。
      </p>
    </div>
  </div>
  <div class="footer">
    <button rectangle variant="primary" (click)="this.close()">OK</button>
  </div>
</div>
