import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'

import { HeaderComponent } from '../../components/header/header.component'

@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.scss',
})
export class MainPageComponent {}
