import { Component } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { RouterLink } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { getTitle } from '../../util/accessibility'

@Component({
  selector: 'app-no-license-page',
  standalone: true,
  imports: [MatIcon, RouterLink],
  templateUrl: './no-license-page.component.html',
  styleUrl: './no-license-page.component.scss',
})
export class NoLicensePageComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle(getTitle('ライセンスエラー'))
  }
}
