import { Component, Input } from '@angular/core'
import { MatTooltip } from '@angular/material/tooltip'

type TooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after'

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [MatTooltip],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() thumbnailUrl = ''
  @Input() tooltip = ''
  @Input() tooltipPosition: TooltipPosition = 'below'
}
