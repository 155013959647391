import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

export type CsvFileUploadErrorItem = {
  rowNo: number
  fileName: string
  message: string
}

export type CsvUploadErrorDialogParam = {
  items: CsvFileUploadErrorItem[]
}

@Component({
  selector: 'app-csv-upload-error',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent],
  templateUrl: './csv-upload-error.component.html',
  styleUrls: ['./csv-upload-error.component.scss'],
})
export class CsvUploadErrorComponent {
  param: CsvUploadErrorDialogParam | null = null

  constructor(
    @Inject(MAT_DIALOG_DATA) param: CsvUploadErrorDialogParam,
    private dialogRef: MatDialogRef<CsvUploadErrorComponent>,
  ) {
    this.param = param
  }

  close(): void {
    this.dialogRef.close()
  }
}
