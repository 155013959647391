<div class="container">
  <button
    type="button"
    mat-icon-button
    class="close-button"
    (click)="this.cancel()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <h2 class="title">ユーザーの{{ this.user ? '編集' : '登録' }}</h2>
  <div class="form">
    <app-admin-user-form
      #adminUserForm
      (validChanges)="this.inputValidChanges($event)"
      [isEdit]="true"
      [user]="this.user"
      [departmentNames]="this.departmentNames"
      [roles]="this.roles"
      [selectableLicenses]="this.selectableLicenses"
      [selectedOption]="this.selectedOption"
    ></app-admin-user-form>
  </div>
  <div class="actions">
    <button
      class="cancel"
      rectangle
      variant="secondary"
      (click)="this.cancel()"
    >
      キャンセル
    </button>
    <button
      class="save"
      rectangle
      [disabled]="!this.inputValid"
      (click)="this.save()"
    >
      保存
    </button>
  </div>
  <app-loading [showLoading]="this.isLoading"></app-loading>
</div>
