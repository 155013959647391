import {
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>

  @Input() multiple = false
  @Input() acceptFileExtensions: string[] = []
  @Input() iconSize = 32
  @Output() uploaded = new EventEmitter<File[]>()

  isDragging = false

  get acceptFileExtensionsForInput(): string {
    return this.acceptFileExtensions
      .map((extension) => `.${extension}`)
      .join(',')
  }

  get acceptFileExtensionsForMessage(): string {
    return this.acceptFileExtensions
      .map((extension) => `${extension}`)
      .join(', ')
  }

  onDragOver(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = true
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = false
  }

  async onDrop(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragging = false
    const files = Array.from(event.dataTransfer?.files || [])

    if (files.length === 0) {
      return
    }
    this.uploaded.emit(files)
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target.files?.length) {
      return
    }
    const files = Array.from(target.files)
    if (files.length === 0) {
      return
    }
    this.uploaded.emit(files)
  }

  selectFile(): void {
    this.fileInput?.nativeElement.click()
  }
}
