import { Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'

import { MatIconRegisterService } from './services/mat-icon-register.service'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(private matIconRegisterService: MatIconRegisterService) {}

  ngOnInit(): void {
    this.matIconRegisterService.register()
  }
}
