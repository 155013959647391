import { Component, Inject, Input, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'

import { catchError, finalize, of, tap } from 'rxjs'

import { ButtonComponent } from '../../../../components/button/button.component'
import { LoadingComponent } from '../../../../components/loading/loading.component'
import { MessageDialogService } from '../../../../components/message-dialog/message-dialog.service'
import { Option } from '../../../../components/select/select.component'
import { UserForAdmin } from '../../../../services/user/user.mapping'
import { CustomErrorCode } from '../../../../services/user/user.service'
import { UserStore } from '../../../../stores/user.store'
import { AdminUserFormComponent } from '../form/admin-user-form.component'

export interface AdminUserRegisterDialogDialogParam {
  departmentNames: string[]
  user: UserForAdmin | null
  roles: { name: string; code: string }[]
  selectableLicenses: Option[]
  selectedOption: Option
}

export interface AdminUserRegisterDialogResult {
  isSucceeded: boolean
}

@Component({
  selector: 'app-admin-user-register',
  templateUrl: './admin-user-register.component.html',
  styleUrls: ['./admin-user-register.component.scss'],
  standalone: true,
  imports: [AdminUserFormComponent, LoadingComponent, ButtonComponent, MatIcon],
})
export class AdminUserRegisterComponent {
  @ViewChild('adminUserForm') adminUserForm!: AdminUserFormComponent
  @Input() departmentNames: string[] = []
  @Input() roles: { name: string; code: string }[] = []
  @Input() user: UserForAdmin | null = null
  @Input() selectableLicenses: Option[] = []
  @Input() selectedOption!: Option

  inputValid: boolean = false
  isLoading: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) param: AdminUserRegisterDialogDialogParam,
    private matDialogRef: MatDialogRef<AdminUserFormComponent>,
    private userStore: UserStore,
    private messageDialogService: MessageDialogService,
  ) {
    this.departmentNames = param.departmentNames
    this.user = param.user
    this.roles = param.roles
    this.selectableLicenses = param.selectableLicenses
    this.selectedOption = param.selectedOption
  }

  inputValidChanges = (valid: boolean) => {
    this.inputValid = valid
  }

  cancel = () => {
    this.matDialogRef.close()
  }

  save = () => {
    const user = this.adminUserForm.getInputUser()
    this.isLoading = true
    if (this.user) {
      this.userStore
        .updateUser({
          id: this.user.id,
          name: user.name,
          email: user.email,
          departmentName: user.departmentName,
          reportToEmail: user.reportToEmail,
          roleCodes: user.roleCodes,
          licenseId: user.licenseId === 'none' ? null : user.licenseId,
        })
        .pipe(
          catchError((error) => {
            const dialogTitle = 'エラー'

            if (error.code === CustomErrorCode.DUPLICATED_USER) {
              this.messageDialogService.showError(
                'このメールアドレスは既に使われています。',
                {
                  title: dialogTitle,
                },
              )
            } else if (error.code === CustomErrorCode.MANAGER_RELATION_LOOP) {
              this.messageDialogService.showError(
                '上司の関係が循環しているため、登録できません。',
                {
                  title: dialogTitle,
                },
              )
            } else {
              this.messageDialogService.showError(
                '予期しないエラーが発生しました。時間をおいて再度お試しください。',
                {
                  title: dialogTitle,
                },
              )
            }
            return of()
          }),
          tap(() => {
            this.matDialogRef.close({ isSucceeded: true })
          }),
          finalize(() => {
            this.isLoading = false
          }),
        )
        .subscribe()
    } else {
      if (!user.licenseId) {
        this.messageDialogService.showError('ライセンスを選択してください。', {
          title: 'エラー',
        })
        return
      }
      this.userStore
        .createUser({
          name: user.name,
          email: user.email,
          departmentName: user.departmentName,
          reportToEmail: user.reportToEmail,
          roleCodes: user.roleCodes,
          licenseId: user.licenseId,
        })
        .pipe(
          catchError((error) => {
            const dialogTitle = 'エラー'

            if (error.code === CustomErrorCode.DUPLICATED_USER) {
              this.messageDialogService.showError(
                'このメールアドレスは既に使われています。',
                {
                  title: dialogTitle,
                },
              )
            } else {
              this.messageDialogService.showError(
                '予期しないエラーが発生しました。時間をおいて再度お試しください。',
                {
                  title: dialogTitle,
                },
              )
            }
            return of()
          }),
          tap(() => {
            this.matDialogRef.close({ isSucceeded: true })
          }),
          finalize(() => {
            this.isLoading = false
          }),
        )
        .subscribe()
    }
  }
}
