import { bootstrapApplication } from '@angular/platform-browser'

import { AppComponent } from './app/app.component'
import { appConfig } from './app/app.config'
import * as Sentry from '@sentry/angular'
import { environment } from './environments/environment'
import packageJson from '../package.json'

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err))

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.breadcrumbsIntegration({
      dom: false,
    }),
  ],
  environment: 'production',
  release: `${packageJson.name}@${packageJson.version}`,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', environment.graphqlUrl],
})
