import { provideHttpClient } from '@angular/common/http'
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core'
import { initializeApp, provideFirebaseApp } from '@angular/fire/app'
import { getAuth, provideAuth } from '@angular/fire/auth'
import { provideStorage, getStorage } from '@angular/fire/storage'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideRouter, Router } from '@angular/router'

import { routes } from './app.routes'
import { graphqlProvider } from './graphql.provider'
import { environment } from '../environments/environment'
import * as Sentry from '@sentry/angular'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth()
      return auth
    }),
    provideHttpClient(),
    provideStorage(() => getStorage()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    graphqlProvider,
  ],
}
