import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'

import { map, switchMap } from 'rxjs'

import { AuthService } from '../services/auth.service'
import { UserStore } from '../stores/user.store'

export const licenseGuard: CanActivateFn = () => {
  const router = inject(Router)
  const authService = inject(AuthService)
  const userStore = inject(UserStore)

  return authService.user$.pipe(
    switchMap(() => {
      return userStore.getOneSelf({ useCache: true }).pipe(
        map((user) => {
          const hasLicense = Boolean(user.license)
          if (hasLicense) {
            return true
          } else {
            router.navigate(['/no-license'])
            return false
          }
        }),
      )
    }),
  )
}
