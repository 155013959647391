<div
  class="multiple-select-control"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
  (keydown.escape)="closed.emit()"
  tabindex="0"
>
  <mat-checkbox
    [checked]="selectAll"
    (change)="onSelectAll($event)"
    [disabled]="this.disableSelectAll"
    ><div class="select-all-label">すべて選択</div></mat-checkbox
  >
  <ng-container *ngFor="let item of filteredItems">
    <mat-checkbox
      [checked]="item.checked"
      [disabled]="item.notSelectable"
      (change)="onSelect(item, $event)"
    >
      <div class="item-label">
        {{ item.label }}
      </div>
    </mat-checkbox>
  </ng-container>
</div>
