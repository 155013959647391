import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Injector,
  InjectionToken,
  ViewContainerRef,
} from '@angular/core'
import { MatIcon } from '@angular/material/icon'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { isEqual } from 'lodash'

import {
  MultipleSelectControlComponent,
  InputData,
} from './multiple-select-control/multiple-select-control.component'
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms'

export type Item = {
  label: string
  id?: string
  checked: boolean
  notSelectable?: boolean
}

export const MULTIPLE_SELECT_INPUT = new InjectionToken('MULTIPLE_SELECT')
@UntilDestroy()
@Component({
  selector: 'app-multiple-select',
  standalone: true,
  imports: [MatIcon, CommonModule],
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultipleSelectComponent,
      multi: true,
    },
  ],
})
export class MultipleSelectComponent implements ControlValueAccessor {
  @Input() formControl: FormControl | null = null
  @Input() fieldName = ''
  @Input() items: Item[] = []
  @Output() readonly changeSelected = new EventEmitter<Item[]>()

  controlOverlay: OverlayRef | null = null

  get checkedItems(): Item[] {
    return this.items.filter((item) => item.checked)
  }

  get allUnChecked(): boolean {
    return this.checkedItems.length === 0
  }

  get allChecked(): boolean {
    return this.checkedItems.length === this.items.length
  }

  get checkedItemsLabel(): string {
    if (this.allUnChecked) return 'なし'
    if (this.allChecked) return 'すべて選択'
    return this.checkedItems.map((item) => item.label).join(', ')
  }

  // NG01203 Missing value accessorの対応
  // 現在、NgModelの利用を想定していないため、警告を抑制するためにダミーの実装を行っている
  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}
  setDisabledState(): void {}

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
  ) {}

  showSelectControl(connectToElem: Element): void {
    this.formControl?.markAllAsTouched()
    if (this.controlOverlay) {
      this.detachControlOverlayOverlay()
    }

    this.controlOverlay = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectToElem)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 2,
          },
        ]),
    })

    const data: InputData = {
      items: this.items,
      fieldName: this.fieldName,
    }
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MULTIPLE_SELECT_INPUT,
          useValue: data,
        },
      ],
    })

    const portal = new ComponentPortal(
      MultipleSelectControlComponent,
      this.viewContainerRef,
      injector,
    )
    const ref = this.controlOverlay.attach(portal)

    let lastSelected: Item[] = this.items
    ref.instance.changeSelected
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        lastSelected = items
      })
    const closeCallback = () => {
      if (!isEqual(this.items, lastSelected)) {
        this.changeSelected.emit(lastSelected)
      }

      this.detachControlOverlayOverlay()
    }
    ref.instance.closed.pipe(untilDestroyed(this)).subscribe(closeCallback)
    this.controlOverlay.backdropClick().subscribe(closeCallback)
  }

  private detachControlOverlayOverlay = (): void => {
    if (this.controlOverlay) {
      this.controlOverlay.detach()
      this.controlOverlay.dispose()
      this.controlOverlay = null
    }
  }
}
