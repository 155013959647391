import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { catchError, from, of, switchMap, tap } from 'rxjs'

import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { AuthService } from '../../services/auth.service'
import { getTitle } from '../../util/accessibility'

interface AuthParam {
  mode: 'resetPassword' | 'recoverEmail' | 'verifyEmail'
  oobCode: string
  apiKey: string
  continueUrl: string
  lang: string
}

@Component({
  standalone: true,
  imports: [MatIcon, MatButton, ResetPasswordComponent, CommonModule],
  template: `
    <ng-container *ngIf="authParam?.mode === 'resetPassword'">
      <app-reset-password
        [isLoading]="isLoading"
        (passwordConfirmed)="this.updatePassword($event)"
      ></app-reset-password>
    </ng-container>
  `,
})
export class AuthActionComponent implements OnInit {
  email: string | null = null
  authParam: AuthParam | null = null
  isLoading = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('パスワードを設定'))
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        tap((params) => {
          this.authParam = {
            mode: params.mode,
            oobCode: params.oobCode,
            apiKey: params.apiKey,
            continueUrl: params.continueUrl,
            lang: params.lang,
          }
        }),
        switchMap((params) => {
          if (this.authParam?.mode === 'resetPassword') {
            return from(
              this.authService.verifyPasswordResetCode(params.oobCode),
            )
          }
          throw new Error('Invalid mode')
        }),
        tap((email) => {
          this.email = email
        }),
        catchError(async (error) => {
          await this.goToResendPage()
          return of(error)
        }),
      )
      .subscribe()
  }

  async updatePassword(password: string): Promise<void> {
    if (!this.authParam || !this.email) {
      this.goToResendPage()
      return
    }
    this.isLoading = true
    try {
      // update password
      await this.authService.confirmPasswordReset(
        this.authParam.oobCode,
        password,
      )
    } catch {
      await this.goToResendPage()
      return
    }
    // if succeeded, login and redirect to home
    await this.authService.loginWithEmailAndPassword(this.email!, password)
    await this.router.navigate(['/home'])
  }

  private async goToResendPage(): Promise<void> {
    await this.router.navigate(['/forgot-password'], {
      state: {
        message:
          'リンクの有効期限が切れました。メールアドレスを入力して、リンクを再送してください。',
      },
    })
  }
}
