import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router'

import { map, switchMap } from 'rxjs'

import { AuthService } from '../services/auth.service'
import { UserStore } from '../stores/user.store'

export const roleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router)
  const activateRoleCodes = route.data.roles as string[]
  const authService = inject(AuthService)
  const userStore = inject(UserStore)

  return authService.user$.pipe(
    switchMap(() => {
      return userStore.getOneSelf({ useCache: true }).pipe(
        map((user) => {
          const userRoleCodes = user.roles.map((role) => role.code)
          if (user.isManager) {
            userRoleCodes.push('MANAGER')
          }

          if (userRoleCodes.some((role) => activateRoleCodes.includes(role))) {
            return true
          } else {
            router.navigate(['/home'])
            return false
          }
        }),
      )
    }),
  )
}
