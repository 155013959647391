<div
  class="file-uploader"
  [class.dragging]="isDragging"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
>
  <input
    type="file"
    #fileInput
    hidden
    [multiple]="this.multiple"
    [accept]="this.acceptFileExtensionsForInput"
    (change)="onFileChange($event)"
  />
  <div class="upload-area">
    <mat-icon
      class="material-icons-outlined"
      [style]="{
        fontSize: iconSize + 'px',
        width: iconSize + 'px',
        height: iconSize + 'px',
      }"
      >cloud_upload</mat-icon
    >
    <p>
      ファイルをここにドラッグ＆ドロップするか、<button
        class="upload-button"
        (click)="selectFile()"
      >
        ファイルを選択
      </button>
    </p>
    <small>対応フォーマット: {{ this.acceptFileExtensionsForMessage }}</small>
  </div>
</div>
