import { Overlay } from '@angular/cdk/overlay'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { Observable } from 'rxjs'

import {
  ConfirmMessageDialogOptions,
  MessageDialogComponent,
  MessageDialogOptions,
} from './message-dialog.component'

export enum MessageType {
  Error,
  Warning,
  Info,
  Confirm,
  Success,
}

export enum DialogResult {
  PrimaryButtonClicked,
  SecondaryButtonClicked,
}

@Injectable({
  providedIn: 'root',
})
export class MessageDialogService {
  constructor(
    private matDialog: MatDialog,
    private overlay: Overlay,
  ) {}

  showError(
    message: string,
    options?: MessageDialogOptions,
  ): Observable<DialogResult> {
    options = Object.assign(
      {
        primaryButtonText: 'OK',
      },
      options,
    )
    return this.showMessageDialog(MessageType.Error, message, options)
  }

  showWarning(
    message: string,
    options?: MessageDialogOptions,
  ): Observable<DialogResult> {
    options = Object.assign(
      {
        primaryButtonText: 'OK',
      },
      options,
    )
    return this.showMessageDialog(MessageType.Warning, message, options)
  }

  showInfo(
    message: string,
    options?: MessageDialogOptions,
  ): Observable<DialogResult> {
    options = Object.assign(
      {
        primaryButtonText: 'OK',
      },
      options,
    )
    return this.showMessageDialog(MessageType.Info, message, options)
  }

  showConfirm(
    message: string,
    options?: ConfirmMessageDialogOptions,
  ): Observable<DialogResult> {
    options = Object.assign(
      {
        primaryButtonText: 'OK',
        secondaryButtonText: 'キャンセル',
      },
      options,
    )
    return this.showMessageDialog(MessageType.Confirm, message, options)
  }

  showSuccess(
    message: string,
    options?: MessageDialogOptions,
  ): Observable<DialogResult> {
    options = Object.assign(
      {
        primaryButtonText: 'OK',
      },
      options,
    )
    return this.showMessageDialog(MessageType.Success, message, options)
  }

  private showMessageDialog(
    messageType: MessageType,
    message: string,
    options?: MessageDialogOptions | ConfirmMessageDialogOptions,
  ): Observable<DialogResult> {
    return this.matDialog
      .open(MessageDialogComponent, {
        data: { messageType, message, options },
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        width: '481px',
        maxHeight: '800px',
        autoFocus: false,
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      })
      .afterClosed()
  }
}
