<div class="message-dialog">
  <div class="title">
    {{ this.options && this.options.title }}
  </div>
  <div class="message">
    <div [innerHTML]="this.message"></div>
  </div>
  <div class="action-button-wrapper">
    <div *ngIf="this.isConfirmDialogOptions(this.options)">
      <button
        rectangle
        class="secondary-button"
        (click)="this.onSecondaryButtonClick()"
      >
        {{ this.secondaryButtonText() }}
      </button>
    </div>
    <div>
      <button
        rectangle
        class="primary-button"
        (click)="this.onPrimaryButtonClick()"
      >
        {{ this.options.primaryButtonText }}
      </button>
    </div>
  </div>
</div>
