import { Component, Input, Output, EventEmitter } from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIcon } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { provideMomentDateAdapter } from '@angular/material-moment-adapter'

import moment, { Moment } from 'moment'
import 'moment/locale/ja'

const MONTH_YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM',
  },
  display: {
    dateInput: 'YYYY/MM',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
}

@Component({
  selector: 'app-month-year-input',
  templateUrl: 'month-year-input.component.html',
  styleUrl: 'month-year-input.component.scss',
  standalone: true,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    provideMomentDateAdapter(MONTH_YEAR_FORMATS),
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
  ],
})
export class MonthYearInputComponent {
  private readonly now = moment().date(1)
  @Output() dateChange = new EventEmitter<Date>()
  readonly selectedDate = new FormControl(this.now)

  @Input() set date(date: Date) {
    const momentDate = moment(date)
    momentDate.date(1)
    this.selectedDate.setValue(momentDate)
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
  ) {
    const ctrlValue = this.selectedDate.value ?? moment()
    ctrlValue.startOf('month')
    ctrlValue.month(normalizedMonthAndYear.month())
    ctrlValue.year(normalizedMonthAndYear.year())
    this.selectedDate.setValue(ctrlValue)
    this.dateChange.emit(ctrlValue.toDate())
    datepicker.close()
  }
}
