import { Injectable, inject } from '@angular/core'
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage'
import { Storage } from '@angular/fire/storage'

import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage = inject(Storage)

  constructor() {}

  uploadFile(file: File, filePath: string): Observable<void> {
    const storageRef = ref(this.storage, filePath)
    const uploadTask = uploadBytesResumable(storageRef, file)
    return new Observable((observer) => {
      uploadTask.on('state_changed', {
        next: () => {
          // upload progress
        },
        error: (error) => {
          observer.error(error)
        },
        complete: () => {
          observer.next()
          observer.complete()
        },
      })
    })
  }

  getDownloadUrl(filePath: string): Observable<string> {
    const storageRef = ref(this.storage, filePath)

    return new Observable((observer) => {
      getDownloadURL(storageRef)
        .then((url) => {
          observer.next(url)
          observer.complete()
        })
        .catch((error) => {
          observer.error(error)
        })
    })
  }
}
