import { Component } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { MatDialog } from '@angular/material/dialog'
import { MatDialogRef } from '@angular/material/dialog'

import {
  CsvUploadErrorComponent,
  CsvUploadErrorDialogParam,
  CsvFileUploadErrorItem,
} from '../messages/csv-upload-error/csv-upload-error.component'

export type CsvBusinessMeeting = {
  fileName: string
  name: string
  accountName: string
  recordDate: Date | null
  userEmail: string
}

@Component({
  selector: 'app-business-meeting-csv-import',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './business-meeting-csv-import.component.html',
  styleUrls: ['./business-meeting-csv-import.component.scss'],
})
export class BusinessMeetingCsvImportComponent {
  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<BusinessMeetingCsvImportComponent>,
  ) {}

  close(): void {
    this.dialogRef.close()
  }

  uploadCsv(): void {
    const csvBusinessMeetings = this.importCsv()

    const errors = this.validateCsv(csvBusinessMeetings)
    if (errors.length > 0) {
      this.openErrorDialog(errors)
      return
    }

    this.dialogRef.close(csvBusinessMeetings)
  }

  private importCsv(): CsvBusinessMeeting[] {
    return []
  }

  private validateCsv(
    csvBusinessMeetings: CsvBusinessMeeting[],
  ): CsvFileUploadErrorItem[] {
    console.log(csvBusinessMeetings)
    return []
  }

  private openErrorDialog(errors: CsvFileUploadErrorItem[]): void {
    this.dialog.open<CsvUploadErrorComponent, CsvUploadErrorDialogParam>(
      CsvUploadErrorComponent,
      {
        disableClose: true,
        panelClass: 'unset-mat-dialog-padding',
        data: {
          items: errors,
        },
      },
    )
  }
}
