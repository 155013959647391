<div class="container">
  <h1 class="header">
    <div class="title">
      <mat-icon>error_outline</mat-icon>
      ライセンスの制限時間を超えました
    </div>
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="main">
    <p>
      アップロードできるファイルの制限時間は一人当たり{{
        getUserMonthlyLimitHours()
      }}時間です。アップロードされたファイルは、各ユーザーに割り当てられたライセンスの上限を超えています。
    </p>
    <div class="license-exceeded-error">
      <p class="exceeded-list-heading">制限時間を超過したユーザー：</p>
      <ul class="exceeded-list" *ngFor="let item of this.param?.items">
        <li>
          {{ item.userName
          }}<span class="caption"
            >（{{ secondsToHours(item.exceededSeconds) }}時間）</span
          >
        </li>
      </ul>
      <p class="resolutions-heading">対処方法:</p>
      <ul class="resolutions">
        <li>
          <div class="heading">制限時間の購入</div>
          ライセンスの制限時間を追加で購入することができます。購入オプションについては営業担当者に連絡してください。
        </li>
        <li>
          <div class="heading">翌月まで待つ</div>
          次の月が始まると、アップロード可能なファイル時間の制限がリセットされます。それまでお待ちください。
        </li>
      </ul>
    </div>
  </div>
  <div class="footer">
    <button rectangle variant="primary" (click)="this.close()">OK</button>
  </div>
</div>
