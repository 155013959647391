import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { DialogResult, MessageType } from './message-dialog.service'
import { ButtonComponent } from '../button/button.component'

export interface MessageDialogOptions {
  title?: string
  primaryButtonText?: string
}
export interface ConfirmMessageDialogOptions extends MessageDialogOptions {
  secondaryButtonText?: string
}

@Component({
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonComponent],
})
export class MessageDialogComponent {
  messageType: MessageType
  message = ''
  options: MessageDialogOptions | ConfirmMessageDialogOptions

  public isConfirmDialogOptions(
    options: MessageDialogOptions | ConfirmMessageDialogOptions,
  ): options is ConfirmMessageDialogOptions {
    return 'secondaryButtonText' in options
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    ownDialogParameter: {
      messageType: MessageType
      message: string
      options: MessageDialogOptions | ConfirmMessageDialogOptions
    },
    private ownDialogRef: MatDialogRef<MessageDialogComponent>,
  ) {
    this.messageType = ownDialogParameter.messageType
    this.message = ownDialogParameter.message
    this.options = ownDialogParameter.options
  }

  onPrimaryButtonClick(): void {
    this.ownDialogRef.close(DialogResult.PrimaryButtonClicked)
  }

  onSecondaryButtonClick(): void {
    this.ownDialogRef.close(DialogResult.SecondaryButtonClicked)
  }

  secondaryButtonText(): string {
    if ('secondaryButtonText' in this.options) {
      return this.options.secondaryButtonText ?? ''
    }

    return ''
  }
}
