<div class="date-selector">
  <input
    class="date-input"
    [matDatepicker]="datePicker"
    [formControl]="this.inputDate"
    (focus)="datePicker.open()"
    [id]="this.id"
  />
  <div>
    <mat-icon
      class="calendar-icon material-icons-outlined"
      color="primary"
      (click)="datePicker.open()"
      >calendar_today</mat-icon
    >
  </div>
  <mat-datepicker #datePicker></mat-datepicker>
</div>
