<div class="container">
  <h1 class="title">
    商談ファイル情報
    <button
      type="button"
      mat-icon-button
      class="close-button"
      (click)="this.close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div class="file-information-details">
    <div class="file-detail">
      <mat-icon>{{
        this.isAudioFile(this.fileName) ? 'music_video' : 'attachment'
      }}</mat-icon>
      <div class="view-file">
        <span
          class="view-file-label-wrapper"
          [matTooltip]="this.fileName ?? ''"
        >
          <span class="file-name">
            <span class="file-name-text">{{ this.fileName ?? '' }}</span>
          </span>
        </span>
      </div>
    </div>
    <div class="detail">
      <div class="label">商談名：</div>
      <div class="value">
        {{ this.businessMeetingName }}
      </div>
    </div>
    <div class="detail">
      <div class="label">顧客名：</div>
      <div class="value">
        {{ this.accountName }}
      </div>
    </div>
    <div class="detail">
      <div class="label">スキルマップ：</div>
      <div class="value">
        {{ this.skillMapName }}
      </div>
    </div>
    <div class="detail">
      <div class="label">該当フェーズ：</div>
      <div class="value">
        {{ this.phaseName }}
      </div>
    </div>
    <div class="detail">
      <div class="label">記録日：</div>
      <div class="value">
        {{ this.recordDate | date: 'yyyy/MM/dd' }}
      </div>
    </div>
    <div class="detail">
      <div class="label">記録期間：</div>
      <div class="value">
        {{ this.durationTIme }}
      </div>
    </div>
  </div>
</div>
