import { Injectable } from '@angular/core'

import { ComponentStore } from '@ngrx/component-store'
import { catchError, of, tap } from 'rxjs'

import { AssessmentService } from '../services/assessment/assesssment.service'
import { StorageService } from '../services/storage.service'

interface AssessmentState {
  assessmentId: string
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentStore extends ComponentStore<AssessmentState> {
  constructor(
    private assessmentService: AssessmentService,
    private storageService: StorageService,
  ) {
    super({
      assessmentId: '',
    })
  }

  getSkillMaps() {
    return this.assessmentService.getSkillMaps()
  }

  getAccounts() {
    return this.assessmentService.getAccounts()
  }

  getAssessment(assessmentId: string) {
    return this.assessmentService.getAssessment(assessmentId).pipe(
      catchError(() => {
        return of()
      }),
    )
  }

  searchAssessments(
    query: string,
    pagination: { limit: number; offset: number },
    sort?: { sortField: string; sortOrder: 'asc' | 'desc' },
  ) {
    return this.assessmentService
      .searchAssessments(query, pagination, 'COMPLETED', sort)
      .pipe(
        catchError(() => {
          return of()
        }),
      )
  }

  createAssessment(
    userId: string,
    businessMeetingName: string,
    accountName: string,
    recordDate: Date,
    fileName: string,
    skillMapId: string,
    phaseIds: string[],
  ) {
    return this.assessmentService
      .createAssessment({
        userId,
        businessMeetingName,
        accountName,
        recordDate,
        fileName,
        skillMapId,
        phaseIds,
      })
      .pipe(tap(() => {}))
  }

  uploadFile(file: File, filePath: string) {
    return this.storageService.uploadFile(file, filePath)
  }

  markContentAsAccessed(recommendedContentId: string) {
    return this.assessmentService.markContentAsAccessed(recommendedContentId)
  }

  exportAssessments() {
    return this.assessmentService.exportAssessments()
  }

  deleteAssessment(assessmentId: string) {
    return this.assessmentService.deleteAssessment(assessmentId)
  }
}
