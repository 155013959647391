import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'

import { map, tap } from 'rxjs'

import { AuthService } from '../services/auth.service'

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService)
  const router = inject(Router)

  return authService.user$.pipe(
    map((user) => !!user),
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate(['/login'])
      }
    }),
  )
}
