<div class="input-fields">
  <div class="input-field">
    <div class="input-form">
      <label class="input-label required" for="user-name"> 名前 </label>
      <input
        id="user-name"
        class="input-control"
        [placeholder]="'名前を入力してください'"
        [formControl]="this.name"
      />
    </div>
    <div
      class="input-error"
      [ngClass]="{
        'hide-error': !this.name.touched || !this.name.hasError('required'),
      }"
    >
      名前は必須です
    </div>
  </div>
  <div class="input-field">
    <div class="input-form">
      <label class="input-label required" for="email"> メールアドレス </label>
      <input
        id="email"
        inputmode="email"
        class="input-control"
        [placeholder]="'メールアドレスを入力してください'"
        [attr.disabled]="this.emailEditable ? null : 'disabled'"
        [formControl]="this.email"
      />
    </div>
    <div
      class="input-error"
      [ngClass]="{
        'hide-error':
          !this.email.touched ||
          (!this.email.hasError('required') &&
            !this.email.hasError('invalidString')),
      }"
    >
      <span
        *ngIf="
          !this.email.hasError('required') &&
          !this.email.hasError('invalidString')
        "
        >エラー</span
      >
      <span *ngIf="this.email.hasError('required')">
        メールアドレスは必須です
      </span>
      <span *ngIf="this.email.hasError('invalidString')">
        メールアドレスの形式が正しくありません
      </span>
    </div>
  </div>
  <div class="input-field">
    <div class="input-form">
      <label class="input-label required" for="department"> 部署 </label>
      <input
        id="department"
        class="input-control"
        [placeholder]="'部署を入力または選択してください'"
        [formControl]="this.departmentName"
        [matAutocomplete]="department_auto"
      />
    </div>
    <div
      class="input-error"
      [ngClass]="{
        'hide-error':
          !this.departmentName.touched ||
          !this.departmentName.hasError('required'),
      }"
    >
      部署は必須です
    </div>
    <mat-autocomplete #department_auto="matAutocomplete">
      <mat-option
        *ngFor="let departmentName of filteredDepartmentNames | async"
        [value]="departmentName"
      >
        {{ departmentName }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="input-field">
    <div class="input-form">
      <span class="input-label required"> ロール </span>
      <app-multiple-select
        [id]="'applicable-roles'"
        [items]="this.roleItems"
        [formControl]="this.roleCodes"
        (changeSelected)="changeSelectedRoles($event)"
      ></app-multiple-select>
    </div>
    <div
      class="input-error"
      [ngClass]="{
        'hide-error':
          !this.roleCodes.touched || !this.roleCodes.hasError('required'),
      }"
    >
      <span>ロールは必須です</span>
    </div>
  </div>
  <div class="input-field">
    <div class="input-form">
      <label class="input-label" for="report-to-input"> 上司 </label>
      <ng-container *ngIf="!this.reportTo.value">
        <input
          id="report-to-input"
          class="input-control"
          [placeholder]="'上司を選択してください'"
          [formControl]="this.reportToInput"
          [matAutocomplete]="reportTo_auto"
          (blur)="this.reportToInputBlur()"
        />
        <mat-autocomplete
          #reportTo_auto="matAutocomplete"
          (optionSelected)="this.reportToOptionSelected($event)"
        >
          <mat-option
            *ngFor="let user of this.filteredUsers | async"
            [value]="user"
          >
            <span>{{ user.name }}&ensp;</span>
            <span style="font-size: x-small">&lt;{{ user.email }}&gt;</span>
          </mat-option>
        </mat-autocomplete>
      </ng-container>
      <ng-container *ngIf="this.reportTo.value">
        <div class="selected-report-to">
          <mat-chip-listbox>
            <mat-chip>
              {{ this.reportTo.value.name }}
              <button
                type="button"
                matChipRemove
                (click)="this.reportTo.setValue(null)"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>
        </div>
      </ng-container>
    </div>
    <div class="input-error hide-error">
      <span>エラー</span>
    </div>
  </div>
  <div class="input-field">
    <div class="input-form">
      <span class="input-label"> ライセンス </span>
      <app-select
        [id]="'applicable-licenses'"
        [options]="this.selectableLicenses"
        [selectedOption]="this.selectedOption"
        (selectedOptionChange)="selectedOptionChange($event)"
      ></app-select>
    </div>
  </div>
</div>
