<div class="container">
  <div class="main">
    <div class="header">
      <div class="icon"><mat-icon>donut_large</mat-icon></div>
      <h2 class="title">AI Sales Coach</h2>
    </div>
    <div class="content">
      <ng-container *ngIf="!hasSent">
        <div class="navigation">
          <button class="back-button" (click)="backToLogin()">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <h3 class="title">パスワードの再設定</h3>
        <p class="message">
          <ng-container *ngIf="message">
            {{ message }}
          </ng-container>
          <ng-container *ngIf="!message">
            登録されたメールアドレスを入力してください。
            パスワードを設定するためのリンクを送信いたします。
          </ng-container>
        </p>
        <div class="inputs">
          <div class="input-wrapper">
            <form [formGroup]="resetPasswordForm">
              <input
                id="email"
                name="email"
                class="email"
                placeholder="メールアドレス"
                autocomplete="email"
                formControlName="email"
                required
              />
            </form>
          </div>
        </div>
        <div class="button-wrapper">
          <button
            rectangle
            class="primary"
            [disabled]="resetPasswordForm.invalid"
            (click)="sendPasswordResetEmailButtonClick()"
          >
            送信
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="hasSent">
        <p class="description">
          パスワード再設定するためのリンクが記載されたメールを送信しました。
          受信メールをご確認ください。
        </p>
        <div class="button-wrapper">
          <button rectangle class="primary" (click)="backToLogin()">
            ログイン画面に戻る
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
