import { Injectable } from '@angular/core'

import moment from 'moment'

interface CsvHeader<T> {
  label: string
  key: keyof T
}

type CsvData = { [key: string]: string | number | Date | null }

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  downloadCsv<T extends CsvData>(
    fileName: string,
    headers: CsvHeader<T>[],
    data: T[],
  ) {
    const csv = this.convertToCsv(headers, data)
    const bom = new Uint8Array([0xef, 0xbb, 0xbf])
    const blob = new Blob([bom, csv], { type: 'text/csv' })

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = this.escapeFileName(fileName)
    link.click()
    URL.revokeObjectURL(url)
    link.remove()
  }

  private convertToCsv<T extends CsvData>(
    headers: CsvHeader<T>[],
    data: T[],
  ): string {
    const headerRow = headers.map((h) => h.label).join(',')
    const rows = data.map((row) => {
      return headers
        .map((h) => {
          const value = row[h.key]
          if (this.isDate(value)) {
            const date = moment(value).format('YYYY/M/D')
            return this.escapeCsvCell(date)
          } else if (typeof value === 'number') {
            return this.escapeCsvCell(String(value))
          } else {
            return this.escapeCsvCell(value)
          }
        })
        .join(',')
    })
    return [headerRow, ...rows].join('\n')
  }

  private escapeFileName(value: string): string {
    return value.replace(/<|>|:|"|\/|\\|\?|\*/g, '').replace(/\s/g, '_')
  }

  private escapeCsvCell(value: string | null | undefined): string {
    if (value) {
      return (
        '"' + value.replace(/"/g, '""').replace(/\n|\r|\r\n/g, '\r\n') + '"'
      )
    } else {
      return ''
    }
  }

  private isDate(value: unknown): value is Date {
    if (value instanceof Date) {
      return true
    }
    if (typeof value !== 'string') {
      return false
    }
    const date = new Date(value)
    return !isNaN(date.getTime())
  }
}
