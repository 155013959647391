import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIcon } from '@angular/material/icon'

import { tap } from 'rxjs'

import { CustomDateAdapter } from './CustomDateAdapter'

@Component({
  selector: 'app-date-input',
  standalone: true,
  imports: [MatIcon, MatDatepickerModule, ReactiveFormsModule],
  providers: [
    { provide: DateAdapter, useClass: MatDatepickerModule },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent implements OnInit, OnChanges {
  @Input() inputDate = new FormControl()
  @Input() id: string = ''
  @Input() date: Date | null = null
  @Output() dateChanged = new EventEmitter<Date>()

  constructor() {}

  ngOnInit(): void {
    this.inputDate.valueChanges
      .pipe(
        tap((value) => {
          if (value?.isValid) {
            this.dateChanged.emit(value.toDate())
          }
        }),
      )
      .subscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.inputDate.setValue(this.date)
    }
  }
}
