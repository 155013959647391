import { CommonModule, DatePipe, DecimalPipe } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'

import { InfiniteScrollDirective } from 'ngx-infinite-scroll'
import { Subject, finalize, of, tap } from 'rxjs'

import { ButtonComponent } from '../../components/button/button.component'
import { LoadingComponent } from '../../components/loading/loading.component'
import { SearchInputComponent } from '../../components/search-input/search-input.component'
import {
  Option,
  SelectComponent,
} from '../../components/select/select.component'
import { User } from '../../services/user/user.mapping'
import { UserStore } from '../../stores/user.store'
import { getTitle } from '../../util/accessibility'

interface UserRow {
  id: string
  name: string
  department: string
  latestBusinessMeetingName: string
  businessMeetingCount: number
  latestBusinessMeetingDate: Date | null
  averageScore: number | null
}

@Component({
  selector: 'app-user-list-page',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    MatIcon,
    MatTableModule,
    DatePipe,
    DecimalPipe,
    SearchInputComponent,
    InfiniteScrollDirective,
    LoadingComponent,
    SelectComponent,
  ],
  templateUrl: './user-list-page.component.html',
  styleUrl: './user-list-page.component.scss',
})
export class UserListPageComponent implements OnInit {
  @ViewChild('tableContainer') tableContainer: ElementRef | undefined

  readonly PAGE_SIZE_LIMIT = 100

  displayedColumns: string[] = [
    'name',
    'department',
    'latestBusinessMeetingName',
    'businessMeetingCount',
    'latestBusinessMeetingDate',
    'averageScore',
  ]
  dataSource: UserRow[] = []

  selectableDepartments: Option[] = []
  selectedDepartmentNames: string[] = []

  loading = true

  sortField = 'name'
  sortDirection: 'asc' | 'desc' = 'asc'

  offset = 0

  queryName$ = of(null)
  queryName = ''

  constructor(
    private router: Router,
    private userStore: UserStore,
    private titleService: Title,
  ) {
    this.titleService.setTitle(getTitle('メンバー一覧'))
  }

  ngOnInit() {
    this.userStore.getDepartmentNames().subscribe((departmentNames) => {
      this.selectableDepartments = [
        {
          id: '',
          name: '全ての部署',
          isDefault: true,
        },
        ...departmentNames.map((name) => ({
          id: name,
          name,
        })),
      ]
    })

    this.loadUsers()
  }

  navigateToUserDetail(userId: string) {
    this.router.navigate(['/home'], { queryParams: { 'user-id': userId } })
  }

  queryNameChange(queryName: string) {
    this.offset = 0
    this.queryName = queryName
    this.loadUsers()
  }

  sort(sortField: string) {
    this.loading = true
    if (this.sortField === sortField) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    } else {
      this.sortDirection = 'asc'
      this.sortField = sortField
    }

    this.offset = 0
    this.loadUsers()
  }

  departmentSelected(option: Option) {
    if (option) {
      this.offset = 0
      this.selectedDepartmentNames = option.id ? [option.id] : []
      this.loadUsers()
    }
  }

  async loadMoreUsers() {
    this.loading = true
    this.offset += 1
    this.loadUsers()
  }

  private scrollTop() {
    if (this.tableContainer?.nativeElement?.scrollTop) {
      this.tableContainer.nativeElement.scrollTop = 0
    }
  }

  private toUserRow(user: User): UserRow {
    const latestBusinessMeeting = user.latestAssessment?.businessMeeting
    const latestAssessment = user.latestAssessment

    return {
      id: user.id,
      name: user.name,
      department: user.departmentName,
      latestBusinessMeetingName: latestBusinessMeeting?.title || '',
      businessMeetingCount: user.assessments.filter(
        (assessment) => assessment.status === 'COMPLETED',
      ).length,
      latestBusinessMeetingDate: latestBusinessMeeting?.recordDate || null,
      averageScore: latestAssessment ? getAverageScore(latestAssessment) : null,
    }

    function getAverageScore(
      assessment: { keyActionEvaluations: { score: number }[] } | null,
    ): number | null {
      if (!assessment) {
        return null
      }

      if (assessment.keyActionEvaluations.length === 0) {
        return null
      }

      const totalScore = assessment.keyActionEvaluations.reduce(
        (total, keyActionEvaluation) => {
          return total + keyActionEvaluation.score
        },
        0,
      )

      return totalScore / assessment.keyActionEvaluations.length
    }
  }

  private loadUsers(): void {
    this.loading = true
    this.userStore
      .searchUsers(
        this.queryName ? this.queryName : null,
        this.selectedDepartmentNames,
        {
          limit: this.PAGE_SIZE_LIMIT,
          offset: this.PAGE_SIZE_LIMIT * this.offset,
        },
        {
          sortField: this.sortField,
          sortOrder: this.sortDirection,
        },
        false,
      )
      .pipe(
        tap((users) => {
          if (this.offset === 0) {
            this.dataSource = []
            this.scrollTop()
          }
          this.dataSource = this.dataSource.concat(users.map(this.toUserRow))
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe()
  }
}
