import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLink } from '@angular/router'

import { MatIcon } from '@angular/material/icon'
import { MatDialogRef } from '@angular/material/dialog'

import { ButtonComponent } from '../../../../../components/button/button.component'

@Component({
  selector: 'app-bulk-execution-success',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIcon, ButtonComponent],
  templateUrl: './bulk-execution-success.component.html',
  styleUrls: ['./bulk-execution-success.component.scss'],
})
export class BulkExecutionSuccessComponent {
  constructor(private dialogRef: MatDialogRef<BulkExecutionSuccessComponent>) {}

  close(): void {
    this.dialogRef.close()
  }
}
