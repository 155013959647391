<div class="container">
  <div class="main">
    <div class="header">
      <div class="icon"><mat-icon>donut_large</mat-icon></div>
      <h2 class="title">AI Sales Coach</h2>
    </div>
    <div class="content">
      <h3 class="title">パスワードを設定</h3>
      <p class="description">新たに強力なパスワードを設定してください。</p>
      <div class="password-inputs">
        <div class="input-wrapper">
          <input
            id="password"
            name="password"
            [type]="this.visiblePassword ? 'text' : 'password'"
            class="password"
            autocomplete="new-password"
            placeholder="新しいパスワード"
            required
            minlength="8"
            maxlength="100"
            [formControl]="this.passwordCtrl"
          />
          <button
            type="button"
            aria-label="パスワードを表示"
            (click)="this.togglePasswordVisibility()"
          >
            <mat-icon *ngIf="this.visiblePassword">visibility_off</mat-icon>
            <mat-icon *ngIf="!this.visiblePassword">visibility_on</mat-icon>
          </button>
        </div>
        <div class="password-strength" [ngClass]="this.strength">
          <ul class="indicators">
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div class="label">{{ this.strength ?? '' }}</div>
        </div>
        <div class="input-wrapper">
          <input
            id="confirm-password"
            name="confirm-password"
            [type]="this.visibleConfirmPassword ? 'text' : 'password'"
            class="password"
            autocomplete="new-password"
            placeholder="新しいパスワード再入力"
            required
            minlength="8"
            maxlength="100"
            [formControl]="this.confirmPasswordCtrl"
          />
          <button
            type="button"
            aria-label="パスワードを表示"
            (click)="this.toggleConfirmPasswordVisibility()"
          >
            <mat-icon *ngIf="this.visibleConfirmPassword"
              >visibility_off</mat-icon
            >
            <mat-icon *ngIf="!this.visibleConfirmPassword"
              >visibility_on</mat-icon
            >
          </button>
        </div>
        <div class="requirements">
          <div class="item" [class.satisfied]="this.lengthSatisfied">
            <mat-icon>check</mat-icon>
            <div class="text">8文字以上であること</div>
          </div>
          <div class="item" [class.satisfied]="this.characterSatisfied">
            <mat-icon>check</mat-icon>
            <div class="text">
              小文字、大文字、数字、記号をそれぞれ1文字以上含むこと
            </div>
          </div>
          <div class="item" [class.satisfied]="this.complexitySatisfied">
            <mat-icon>check</mat-icon>
            <div class="text">十分に複雑なパスワードであること</div>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button
          rectangle
          [disabled]="!this.isPasswordValid() || this.isLoading"
          (click)="this.confirmPassword()"
        >
          確定
        </button>
      </div>
    </div>
  </div>
  <app-loading [showLoading]="this.isLoading"></app-loading>
</div>
