<div class="date-selector">
  <input
    class="date-input"
    readonly
    [matDatepicker]="datePicker"
    [formControl]="selectedDate"
    (click)="datePicker.open()"
    (keydown.enter)="datePicker.open()"
  />
  <div>
    <mat-icon
      class="calendar-icon material-icons-outlined"
      color="primary"
      (click)="datePicker.open()"
      >calendar_today</mat-icon
    >
  </div>
  <mat-datepicker
    #datePicker
    startView="year"
    (monthSelected)="setMonthAndYear($event, datePicker)"
    panelClass="example-month-picker"
  >
  </mat-datepicker>
</div>
